import React, { startTransition, useContext, useEffect, useRef, useState } from "react";
import {
  HStack,
  Flex,
  Box,
  Image,
  Text,
  Stack,
  InputGroup,
  InputLeftAddon,
  Input,
  useDisclosure,
  Button,
  Tabs,
  TabList,
  Tab,
  TabIndicator,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
  FormControl,
  FormLabel,
  IconButton,
  TabPanels,
  TabPanel,
  InputLeftElement,
  Spinner,
  InputRightElement,
  Divider,
} from "@chakra-ui/react";
import Logo from "../../assets/Admin/logo.png";
import { FaRegBell } from "react-icons/fa";
import { IoSearchOutline } from "react-icons/io5";
import { Avatar } from "@chakra-ui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { JobContext } from "../../context/JobContext";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { CloseIcon, ExternalLinkIcon, HamburgerIcon, SearchIcon } from "@chakra-ui/icons";
import { useFormik } from "formik";
import { passwordValidationSchema } from "../../Validation/CustomerSchema";
import avatar_logo from '../../assets/Admin/avatar.png'
import SearchComponent from "./SearchComponent";
import ReactHtmlParser from 'html-react-parser';

function Navbar({ showAlert }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isChangeOpen, onOpen: onChangeOpen, onClose: onChangeClose } = useDisclosure();
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const { isOpen: isSearchOpen, onOpen: onSearchOpen, onClose: onSearchClose } = useDisclosure();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);


  const navigate = useNavigate();
  const location = useLocation();
  const {
    decryptOnly,
    groupNotificationsByDay,
    formatDate
  } = useContext(JobContext);
  const encrypted = decryptOnly(localStorage.getItem('user'));
  const data = localStorage.getItem('token')

  useEffect(() => {
    if (encrypted && encrypted?.user_id) {
      getCustomerNotificationsById(encrypted?.user_id);
      fetchCustomerDetails();
      const intervalId = setInterval(() => {
        getCustomerNotificationsById(encrypted?.user_id);
      }, 30000);
      
      return () => clearInterval(intervalId);
    }
  }, []);

  const [cusNotifications, setCusNotifications] = useState([])
  const notificationsRef = useRef(cusNotifications);
  const getCustomerNotificationsById = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/notification/getCustomerNotificationsById?customer_id=${id}`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    notificationsRef.current = json
    if (json.success && JSON.stringify(notificationsRef.current) !== JSON.stringify(json.notification)) {
      notificationsRef.current = json.notification;
      setCusNotifications(json.notification);
    }
  };

  const markSeen = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/notification/markCustomerNotificationSeen`,
      {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ notification_id: id }),
      }
    );
    const json = await response.json();
    if (json.success) {
      getCustomerNotificationsById(encrypted?.user_id);
    } else {
      showAlert(json.error, "error");
    }
  };


  const markAllSeen = async () => {
    setLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/notification/markAllCustomerNotificationSeen`,
      {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ customer_id: encrypted?.user_id }),
      }
    );
    const json = await response.json();
    if (json.success) {
      setLoading(false);
      getCustomerNotificationsById(encrypted?.user_id);
    } else {
      setLoading(false);
      showAlert(json.error, "error");
    }
  };

  const [userDetails, setUserDetails] = useState(null)
  const fetchCustomerDetails = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/customers/getCustomerById?customer_id=${encrypted?.user_id}`,
        {
          headers: { Authorization: localStorage.getItem("token") },
        }
      );
      const json = await response.json();
      if (json.success) {
        setUserDetails(json.customer);
      }
    } catch (error) {
      console.error("Error fetching employee details:", error);
    }
  };

  const sortedNotification = groupNotificationsByDay(cusNotifications);
  const seenNotifications = groupNotificationsByDay(
    cusNotifications?.filter((element) => element.seen === "1")
  );
  const unSeenNotifications = groupNotificationsByDay(
    cusNotifications?.filter((element) => element.seen === "0")
  );

  const NotificationElement = ({ element, onClose }) => {
    return (
      <Box
        bgColor={element.seen === "0" ? "#5a82dc3b" : "#fafbff"}
        p={2}
        borderTop="1px solid #ccc"
        borderBottom="1px solid #ccc"
        cursor="pointer"
        _hover={{ backgroundColor: "#0000001c" }}
        transition="0.25s"
        onClick={() => markSeen(element.id)}
      >
        <Flex gap={2} px={3} position="relative" alignItems="center">
          <Box>
            <Avatar size="md" name="Data Care" src={avatar_logo} />
          </Box>
          <Box>
            <Text fontSize="14px" fontWeight="500">
              {element?.topic}
            </Text>
            <Text fontSize="12px">{element?.content ? ReactHtmlParser(element?.content) : ''}</Text>
            <Flex justifyContent='space-between' alignItems='center'>
              <Text fontSize="11px" color="#807f7f">
                {formatDate(element?.created_at, true)}
              </Text>
              {element.links ? <Link to={element.links} onClick={onClose}>
                <Button size='xs' rightIcon={<ExternalLinkIcon fontSize='13px' />} colorScheme='blackAlpha' variant='outline'>
                  Open
                </Button>
              </Link> : <Text w='max-content'></Text>}
            </Flex>
          </Box>
          <Box
            position="absolute"
            top="50%"
            left="-4px"
            width="7px"
            height="7px"
            borderRadius={5}
            bgColor={element.seen === "0" ? "#5a82dc" : "transparent"}
          ></Box>
        </Flex>
      </Box>
    );
  };

  const [loading, setLoading] = useState(false);

  const initialValue = {
    current_password: "",
    new_password: "",
  }

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: initialValue,
    validationSchema: passwordValidationSchema,
    onSubmit: async (value) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/customers/updatePassword`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ customer_id: encrypted?.user_id, password: value.new_password, })
        })

        const json = await response.json();
        if (json.success) {
          showAlert(json.success, 'success')
          setLoading(false);
          onChangeClose();
          resetForm({ initialValue })
        } else {
          showAlert(json.error, 'error')
          setLoading(false);
        }
      } catch (error) {
        showAlert('Internal server error', 'error')
        setLoading(false);
      }
    },
  });


  const isCustomer = location.pathname.toLowerCase().startsWith('/customer');
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const globalSearch = async () => {
    try {
      if (searchQuery === '') {
        return;
      }
      const response = await fetch(`${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/reports/cusSearch?query=${searchQuery}&customer_id=${encrypted?.user_id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("authToken") || localStorage.getItem("token")
        },
      })
      const json = await response.json();
      if (json.success) {
        setSearchResults(json.searchResults)
        setLoading(false)
      } else {
        console.log(json.error);
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      showAlert("Internal server error", 'error')
    }
  }

  useEffect(() => {
    if (searchQuery) {
      setLoading(true)
    }
    const debounceFetch = setTimeout(globalSearch, 300);

    return () => {
      return () => clearTimeout(debounceFetch);
    }
  }, [searchQuery])

  const handleLogout = () => {
    startTransition(() => {
      localStorage.removeItem(data);
      // unregisterServiceWorker();
      navigate("/");
    });
  };

  return (
    <>
      <HStack
        p={{
          xl: "0px 20px",
          lg: "0px 20px",
          md: "0px 20px",
          sm: "0px 5px",
          base: "0px",
        }}
        justifyContent="space-between"
        w="100%"
        height="83px"
        bg="#fff !important"
        zIndex={111}
        boxShadow={isCustomer === "/customer" ? " 0 0 3px #ccc;" : null}
      >
        <Flex
          w="100%"
          gap={{ xl: 35, lg: 25, md: 13, sm: 5, base: 2 }}
          alignItems="center"
          justifyContent="space-between"
          ml='10px'
        >
          <Flex
            w="100%"
            h="100%"
            justifyContent='space-between'
            alignItems="center"
            gap={{ xl: 3, lg: 3, md: 2, sm: 5, base: 2 }}
          >
            <Flex mr="0px" gap={2}>
              <IconButton
                display={{ '2xl': 'none', xl: 'none', lg: 'none', md: 'block', sm: 'block', base: 'block' }}
                onClick={onDrawerOpen}
                aria-label='Search database'
                icon={<HamburgerIcon fontSize='15px' />}
                size='sm'
                bg='transparent'
                _hover={{
                  bgColor: '#007bff29',
                  // boxShadow:'0 0 5px #007bff59',
                  color: '#007bff'
                }}
                sx={{
                  '&:hover p': {
                    color: '#007bff'
                  }
                }}
                transition='0.25s' />
              <Image
                src={Logo}
                alt="logo" onClick={() => navigate('/customer/dashboard')}
                cursor='pointer'
                w={{
                  xl: "160px",
                  lg: "160px",
                  md: "160px",
                  sm: "160px",
                  base: "160px",
                }}
                h="auto"
              />
            </Flex>
            <Flex justifyContent='space-evenly' gap={2} alignItems="center">
              <Link to='/customer/dashboard'
              >
                <Box
                  display={{
                    xl: "block",
                    lg: "block",
                    md: "none",
                    sm: "none",
                    base: "none",
                  }}
                  bgColor={location.pathname === '/customer/dashboard' ? '#007bff29' : 'transparent'}
                  borderRadius='4px'
                  p='4px 12px'
                  _hover={{
                    bgColor: '#007bff29',
                    // boxShadow:'0 0 5px #007bff59',
                    color: '#007bff'
                  }}
                  sx={{
                    '&:hover p': {
                      color: '#007bff'
                    }
                  }}
                  transition='0.25s'
                >
                  <Text
                    color={location.pathname === '/customer/dashboard' ? '#007bff' : "#000000"}
                    fontSize={{
                      xl: "15px",
                      lg: "13px",
                      md: "13px",
                      sm: "15px",
                      base: "15px",
                    }}
                    // _hover={{
                    //   bgColor: '#007bff29',
                    //   borderRadius:'2px',
                    //   boxShadow:'0 0 5px #007bff59',
                    //   color:'#007bff'
                    // }}
                    fontWeight="500"
                    cursor="pointer"
                  >
                    Dashboard
                  </Text>
                </Box>
              </Link>
              <Link
                to='/customer/jobs'
              >
                <Box
                  display={{
                    xl: "block",
                    lg: "block",
                    md: "none",
                    sm: "none",
                    base: "none",
                  }}
                  p='4px 12px'
                  bgColor={location.pathname === '/customer/jobs' ? '#007bff29' : 'transparent'}
                  borderRadius='3px'
                  _hover={{
                    bgColor: '#007bff29',
                    // boxShadow:'0 0 5px #007bff59',
                    color: '#007bff'
                  }}
                  sx={{
                    '&:hover p': {
                      color: '#007bff'
                    }
                  }}
                  transition='0.25s'
                >
                  <Text
                    color={location.pathname === '/customer/jobs' ? '#007bff' : "#000000"}
                    fontSize={{
                      xl: "15px",
                      lg: "13px",
                      md: "13px",
                      sm: "15px",
                      base: "15px",
                    }}
                    fontWeight="500"
                    cursor="pointer"
                  >
                    Jobs
                  </Text>
                </Box>
              </Link>
              <Link
                to='/customer/pickupdrop'
              >
                <Box
                  w="max-content"
                  display={{
                    xl: "block",
                    lg: "block",
                    md: "none",
                    sm: "none",
                    base: "none",
                  }}
                  p='4px 12px'
                  bgColor={location.pathname === '/customer/pickupdrop' ? '#007bff29' : 'transparent'}
                  borderRadius='3px'
                  _hover={{
                    bgColor: '#007bff29',
                    // boxShadow:'0 0 5px #007bff59',
                    color: '#007bff'
                  }}
                  sx={{
                    '&:hover p': {
                      color: '#007bff'
                    }
                  }}
                  transition='0.25s'
                >
                  <Text
                    w="max-content"
                    color={location.pathname === '/customer/pickupdrop' ? '#007bff' : "#000000"}
                    fontSize={{
                      xl: "15px",
                      lg: "13px",
                      md: "13px",
                      sm: "15px",
                      base: "15px",
                    }}
                    fontWeight="500"
                    cursor="pointer"
                  >
                    Pickup/Drops
                  </Text>
                </Box>
              </Link>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          // justifyContent='center'
          alignItems="center"
          // w='100%'
          mr="10px"
        // gap={{ xl: 25, lg: 25, md: 13, sm: 5, base: 2 }}
        >
          <Box
            mx="10px"
            minW={{
              xl: "250px",
              lg: "150px",
              md: "150px",
              sm: "250px",
              base: "250px",
            }}
            display={{
              xl: "block",
              lg: "block",
              md: "none",
              sm: "none",
              base: "none",
            }}
          >

            <Stack spacing={4}>
              {/* <Tooltip label="Global Search" fontSize='12px'> */}
                <InputGroup bg="#F1F5F7" borderRadius="10px" cursor='pointer' _hover={{ filter: 'brightness(90%)' }} onClick={onSearchOpen}>
                  <InputLeftAddon border="0" px="10px" bg="#F1F5F7">
                    {" "}
                    <IoSearchOutline fontSize="20" />{" "}
                  </InputLeftAddon>
                  <Input
                    cursor='pointer'
                    type="text"
                    px="5px"
                    placeholder="Search"
                    border="0"
                    outline="none"
                    _focus={{ boxShadow: "none", border: "0px" }}
                    borderRadius="10px"
                  />
                </InputGroup>
              {/* </Tooltip> */}
            </Stack>
          </Box>

          <Menu>
            <Tooltip isOpen={isTooltipOpen} fontSize='12px' label={`${userDetails?.name} (You)`}>
              <MenuButton
                as={Button}
                w="max-content"
                p={0}
                h="max-content"
                minW="max-content"
                borderRadius={50}
                onClick={() => {setIsTooltipOpen(false)}}
                onMouseOver={() => setIsTooltipOpen(true)}
                onMouseOut={() => setIsTooltipOpen(false)}
              >
                <Box
                // display={{
                //   xl: "block",
                //   lg: "block",
                //   md: "none",
                //   sm: "none",
                //   base: "none",
                // }}
                >
                  <Avatar
                    size="sm"
                    name={userDetails?.name}
                    src={userDetails?.profile_photo ? userDetails?.profile_photo : ''}
                  />
                </Box>
              </MenuButton>
            </Tooltip>
            <MenuList>
              <MenuItem
                as='a'
                href={`/#/customer/profile`}
                fontSize="14px"
                onClick={(e) => { e.preventDefault(); navigate(`/customer/profile`) }}
              >
                <Link to={`/#/customer/profile`}>
                  <Text>Profile</Text>
                </Link>
              </MenuItem>

              <MenuItem
                fontSize="14px"
                onClick={onChangeOpen}
              >
                <Link>
                  <Text>Change Password</Text>
                </Link>
              </MenuItem>
              <MenuItem
                fontSize="14px"
                onClick={handleLogout}>
                Log Out
              </MenuItem>
            </MenuList>
          </Menu>
          <Tooltip label='Notifications' fontSize='12px'>
            <Box ml="10px" position='relative'>
              <Box position='absolute' bgColor='#e45959' p={1.5} lineHeight='0.5' borderRadius={10} top='-10px' right='-10px'>
                <Text fontSize='11px' color='white' key={cusNotifications?.filter((element) => element.seen === "0").length}>{cusNotifications?.filter((element) => element.seen === "0").length > 9 ? `9+` : cusNotifications?.filter((element) => element.seen === "0").length}</Text>
              </Box>
              <FaRegBell fontSize="20" onClick={onOpen} cursor="pointer" />
            </Box>
          </Tooltip>
        </Flex>

        <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="sm">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Notifications</DrawerHeader>

            <DrawerBody p="0px" bgColor="#fafbff">
              <Tabs position="relative" variant="unstyled">
                <Flex justifyContent="space-between" bgColor="#fff">
                  <TabList px={3}>
                    <Tab fontSize="13px" _active={{ color: "#5a80dc" }}>
                      All
                    </Tab>
                    <Tab fontSize="13px" _active={{ color: "#5a80dc" }}>
                      Unread
                    </Tab>
                    <Tab fontSize="13px" _active={{ color: "#5a80dc" }}>
                      Read
                    </Tab>
                  </TabList>
                  <Button
                    fontSize="12px"
                    padding="0px 8px"
                    height="auto"
                    color="#5a80dc"
                    bg="transparent"
                    onClick={markAllSeen}
                    isLoading={loading}
                  >
                    Mark all as read
                  </Button>
                </Flex>
                <TabIndicator
                  mt="-1.5px"
                  height="2px"
                  bg="blue.500"
                  borderRadius="1px"
                />
                <TabPanels height="100%">
                  <TabPanel px={0} bgColor="#fafbff">
                    {sortedNotification.length !== 0 ? (
                      sortedNotification?.map((element, index) => (
                        <Box key={index}>
                          <Text
                            fontSize="12px"
                            px={1}
                            mt={3}
                            mb={2}
                            letterSpacing="1.5px"
                          >
                            {element.day.toUpperCase()}
                          </Text>
                          {element.notifications?.map((element, index) => (
                            <NotificationElement
                              key={index}
                              element={element}
                              onClose={onClose}
                            />
                          ))}
                        </Box>
                      ))
                    ) : (
                      <Text textAlign="center" fontSize="13px">
                        Nothing here
                      </Text>
                    )}
                  </TabPanel>
                  <TabPanel px={0} bgColor="#fafbff">
                    {unSeenNotifications.length !== 0 ? (
                      unSeenNotifications?.map((element, index) => (
                        <Box key={index}>
                          <Text
                            fontSize="12px"
                            px={1}
                            mt={3}
                            mb={2}
                            letterSpacing="1.5px"
                          >
                            {element.day.toUpperCase()}
                          </Text>
                          {element.notifications?.map((element, index) => (
                            <NotificationElement
                              key={index}
                              element={element}
                            />
                          ))}
                        </Box>
                      ))
                    ) : (
                      <Text textAlign="center" fontSize="13px">
                        Nothing here
                      </Text>
                    )}
                  </TabPanel>
                  <TabPanel px={0} bgColor="#fafbff">
                    {seenNotifications.length !== 0 ? (
                      seenNotifications?.map((element, index) => (
                        <Box key={index}>
                          <Text
                            fontSize="12px"
                            px={1}
                            mt={3}
                            mb={2}
                            letterSpacing="1.5px"
                          >
                            {element.day.toUpperCase()}
                          </Text>
                          {element.notifications?.map((element, index) => (
                            <NotificationElement
                              key={index}
                              element={element}
                            />
                          ))}
                        </Box>
                      ))
                    ) : (
                      <Text textAlign="center" fontSize="13px">
                        Nothing here
                      </Text>
                    )}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        <Modal isOpen={isChangeOpen} onClose={onChangeClose} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Change Password</ModalHeader>
            <ModalCloseButton />
            <ModalBody as='form'>
              <FormControl>
                <FormLabel fontSize="13px" mb='0px'>
                  Current Password
                  <Text as="span" color="red" fontSize="12" ml={1}>
                    *
                  </Text>
                </FormLabel>
                <Input
                  type="password"
                  fontSize="14px"
                  alignContent={"center"}
                  placeholder="Password"
                  onChange={handleChange}
                  name="current_password"
                  value={values.current_password}
                />
                {errors.current_password && touched.current_password && (
                  <Text
                    className="form-error"
                    fontSize="15px"
                    color="#fc1812"
                  >
                    {errors.current_password}
                  </Text>
                )}
              </FormControl>
              <FormControl mt={4}>
                <FormLabel fontSize="13px" mb='0px'>
                  New Password
                  <Text as="span" color="red" fontSize="12" ml={1}>
                    *
                  </Text>
                </FormLabel>
                <Input
                  fontSize="14px"
                  placeholder="New password"
                  type="password"
                  onChange={handleChange}
                  value={values.new_password}
                  name="new_password"
                />
                {errors.new_password && touched.new_password && (
                  <Text
                    className="form-error"
                    fontSize="15px"
                    color="#fc1812"
                  >
                    {errors.new_password}
                  </Text>
                )}
              </FormControl>

            </ModalBody>
            <ModalFooter gap={2}>
              <Button
                onClick={() => {
                  onChangeClose();
                  resetForm({ initialValue })
                }}
                color="#ff5b5b"
                border="1px solid #ff5b5b"
                fontSize="14px"
                background="transparent"
                _hover={{ color: "#ff5b5b", background: "transparent" }}
              >
                Cancel
              </Button>
              <Button

                fontSize="14px"
                bgColor="#030228"
                color="white"
                onClick={handleSubmit}
                isLoading={loading}
                _hover={{ color: "white", background: "#030228" }}
              >
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Drawer isOpen={isDrawerOpen} placement="left" onClose={onDrawerClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader bgColor="#fafbff" >Menu</DrawerHeader>
            <DrawerBody p="0px" bgColor="#fafbff">
              <Box
                w="100%"
                gap={{ xl: 35, lg: 25, md: 13, sm: 5, base: 2 }}
                alignItems="center"
                justifyContent="space-between"
              >
                <Box
                  w="100%"
                  h="100%"
                  justifyContent='space-between'
                  alignItems="center"
                  gap={{ xl: 3, lg: 3, md: 2, sm: 5, base: 2 }}
                >
                  <Box
                    mx="10px"
                    minW={{
                      xl: "250px",
                      lg: "150px",
                      md: "150px",
                      sm: "250px",
                      base: "250px",
                    }}
                  >

                    <Stack spacing={4}>
                      <InputGroup bg="#F1F5F7" borderRadius="10px">
                        <InputLeftAddon border="0" px="10px" bg="#F1F5F7">
                          {" "}
                          <IoSearchOutline fontSize="20" />{" "}
                        </InputLeftAddon>
                        <Input
                          type="text"
                          px="5px"
                          placeholder="search"
                          border="0"
                          outline="none"
                          _focus={{ boxShadow: "none", border: "0px" }}
                          borderRadius="10px"
                        />
                      </InputGroup>
                    </Stack>
                  </Box>
                  <Flex flexDir='column' justifyContent='space-evenly' gap={2} p={2} justifyItems='center'>
                    <Link to='/customer/dashboard' onClick={onDrawerClose}>
                      <Box
                        bgColor={location.pathname === '/customer/dashboard' ? '#007bff29' : 'transparent'}
                        borderRadius='4px'
                        p='4px 12px'
                        _hover={{
                          bgColor: '#007bff29',
                          // boxShadow:'0 0 5px #007bff59',
                          color: '#007bff'
                        }}
                        sx={{
                          '&:hover p': {
                            color: '#007bff'
                          }
                        }}
                        transition='0.25s'
                      >
                        <Text
                          color={location.pathname === '/customer/dashboard' ? '#007bff' : "#000000"}
                          fontSize={{
                            xl: "15px",
                            lg: "13px",
                            md: "13px",
                            sm: "15px",
                            base: "15px",
                          }}
                          // _hover={{
                          //   bgColor: '#007bff29',
                          //   borderRadius:'2px',
                          //   boxShadow:'0 0 5px #007bff59',
                          //   color:'#007bff'
                          // }}
                          fontWeight="500"
                          cursor="pointer"
                        >
                          Dashboard
                        </Text>
                      </Box>
                    </Link>
                    <Link to='/customer/jobs' onClick={onDrawerClose}>
                      <Box
                        p='4px 12px'
                        bgColor={location.pathname === '/customer/jobs' ? '#007bff29' : 'transparent'}
                        borderRadius='3px'
                        _hover={{
                          bgColor: '#007bff29',
                          // boxShadow:'0 0 5px #007bff59',
                          color: '#007bff'
                        }}
                        sx={{
                          '&:hover p': {
                            color: '#007bff'
                          }
                        }}
                        transition='0.25s'
                      >
                        <Text
                          color={location.pathname === '/customer/jobs' ? '#007bff' : "#000000"}
                          fontSize={{
                            xl: "15px",
                            lg: "13px",
                            md: "13px",
                            sm: "15px",
                            base: "15px",
                          }}
                          fontWeight="500"
                          cursor="pointer"
                        >
                          Jobs
                        </Text>
                      </Box>
                    </Link>

                    <Link to='/customer/pickupdrop' onClick={onDrawerClose}>
                      <Box
                        w="max-content"
                        p='4px 12px'
                        bgColor={location.pathname === '/customer/pickupdrop' ? '#007bff29' : 'transparent'}
                        borderRadius='3px'
                        _hover={{
                          bgColor: '#007bff29',
                          // boxShadow:'0 0 5px #007bff59',
                          color: '#007bff'
                        }}
                        sx={{
                          '&:hover p': {
                            color: '#007bff'
                          }
                        }}
                        transition='0.25s'
                      >
                        <Text
                          w="max-content"
                          color={location.pathname === '/customer/pickupdrop' ? '#007bff' : "#000000"}
                          fontSize={{
                            xl: "15px",
                            lg: "13px",
                            md: "13px",
                            sm: "15px",
                            base: "15px",
                          }}
                          fontWeight="500"
                          cursor="pointer"
                        >
                          Pickup/Drops
                        </Text>
                      </Box>
                    </Link>

                  </Flex>
                </Box>
                <Flex
                  // justifyContent='center'
                  alignItems="center"
                  // w='100%'
                  mr="10px"
                // gap={{ xl: 25, lg: 25, md: 13, sm: 5, base: 2 }}
                >
                </Flex>
              </Box>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        <Modal isCentered isOpen={isSearchOpen} onClose={() => { onSearchClose(); setSearchQuery(''); setSearchResults([]) }} size='xl'>
          <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(3px)' />
          <ModalContent>
            <ModalHeader>
              <InputGroup size='md'>
                <InputLeftElement pointerEvents='none' color='gray.300' fontSize='1.2em'>
                  {loading ? <Spinner size='sm' color='gray.600' /> : <SearchIcon color='gray.600' fontSize='xs' />}
                </InputLeftElement>
                <Input
                  type={'text'}
                  fontSize='13px'
                  placeholder='Type anything...'
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <InputRightElement width='2.5rem'>
                  <IconButton size='xs' aria-label='Search database' icon={<CloseIcon />} onClick={() => { setSearchQuery(''); setSearchResults([]) }} bgColor='transparent' />
                </InputRightElement>
              </InputGroup>
            </ModalHeader>
            <ModalBody p={0}>
              {searchResults[0] ? <Tabs>
                <TabList overflowX='auto' overflowY='hidden'>
                  {[{ category: 'All' }, ...searchResults]?.map((element, index) => <Tab key={index} fontSize='12px'>{element.category}</Tab>)}
                </TabList>
                <TabPanels>
                  <TabPanel p={0} mt={2} overflowY='scroll' maxH='calc(100vh - 300px)' h='max-content'>
                    {searchResults[0] && searchResults?.map((element, index) => {
                      return <Box>
                        <SearchComponent key={index} category={element.category} result={element.result} handleCloseSearch={onSearchClose} />
                        <Divider my={1} />
                      </Box>
                    })}
                  </TabPanel>
                  {searchResults?.map((elem, index) => <TabPanel key={index} p={0} mt={2} overflowY='scroll' maxH='calc(100vh - 300px)' h='max-content'>
                    {searchResults[0] && searchResults?.filter(element => element.category === elem.category).map((element, index) => {
                      return <Box>
                        <SearchComponent key={index} category={element.category} result={element.result} handleCloseSearch={onSearchClose} />
                        <Divider my={1} />
                      </Box>
                    })}
                  </TabPanel>)}
                </TabPanels>
              </Tabs> : <Text fontSize='13px' textAlign='center'>{searchQuery !== '' && (!searchResults[0] && 'Not found')}</Text>}
            </ModalBody>
            <ModalFooter>
              <Button onClick={() => { onSearchClose(); setSearchQuery(''); setSearchResults([]) }} size='sm'>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </HStack>
    </>
  );
}

export default Navbar;
