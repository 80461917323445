import { Box, Divider, Flex, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { TbDatabaseCog, TbEdit } from 'react-icons/tb';
import { FiUsers } from 'react-icons/fi';
import { LuCalendarDays } from 'react-icons/lu';
import { MdOutlineInsertChartOutlined } from "react-icons/md";



const Sidebar = () => {

    const navigate = useNavigate();
    const location = useLocation();

    return (
        <>
            {/* {location.pathname !== '/userlogin' && */}
            <VStack w='270px' transition='0.5s' position='sticky' top='1px' bg='#f7f8fa' p='15px 12px' alignItems='flex-start' h='calc(100vh - 83px)' overflowY='auto' overflowX='hidden' display={{ xl: 'flex', lg: 'flex', md: 'none', sm: 'none', base: 'none' }}>
                <Flex justifyContent="space-between" alignItems="center" gap='5px'>
                    <Flex bg="#4165f9b0" color="white" p="10px" alignItems="center" borderRadius='50%'>
                        <TbDatabaseCog fontSize="20px" />
                    </Flex>
                    <Box as="button" p={1.5} textAlign='left'>
                        <Text fontSize="17px">Settings</Text>
                        <Text color='#6f6f6f' fontSize='14px'>CRM Management</Text>
                    </Box>
                </Flex>

                <Divider p='10px' />
                {/* <Box bgColor='white' onClick={() => handleClick(0)}> */}
                <Flex as='button' gap={3} alignItems='center' bgColor={location.pathname === '/setting/business' ? 'white' : 'transparent'} onClick={() => navigate('/setting/business')} my={0.5} _hover={{ backgroundColor: 'white' }} p='10px 12px' borderRadius='10px' transition='.25s' w='100%'>
                    <FiUsers fontSize='20' color={location.pathname === '/setting/business' ? 'rgb(0, 115, 255)' : '#6f6f6f'} />
                    <Text color={location.pathname === '/setting/business' ? 'rgb(0, 115, 255)' : '#6f6f6f'} fontSize='15px' textAlign={'left'}>Business Information</Text>
                </Flex>
                {/* </Box> */}
                {/* <Box className={activeIndex === 1 ? 'active' : 'inactive'} onClick={() => handleClick(1)} > */}
                <Flex as='button' gap={3} alignItems='center' bgColor={location.pathname === '/setting/general_setting' ? 'white' : 'transparent'} onClick={() => navigate('/setting/general_setting')} my={0.5} _hover={{ backgroundColor: 'white' }} p='10px 12px' borderRadius='10px' transition='.25s' w='100%'>
                    <LuCalendarDays fontSize='20' color={location.pathname === '/setting/general_setting' ? 'rgb(0, 115, 255)' : '#6f6f6f'} />
                    <Text color={location.pathname === '/setting/general_setting' ? 'rgb(0, 115, 255)' : '#6f6f6f'} fontSize='15px'>General Settings</Text>
                </Flex>
                {/* </Box> */}
                {/* <Box className={activeIndex === 2 ? 'active' : 'inactive'} onClick={() => handleClick(2)}> */}
                <Flex as='button' gap={3} alignItems='center' bgColor={location.pathname === '/setting/job_setting' ? 'white' : 'transparent'} onClick={() => navigate('/setting/job_setting')} my={0.5} _hover={{ backgroundColor: 'white' }} p='10px 12px' borderRadius='10px' transition='.25s' w='100%'>
                    <TbEdit fontSize='20' color={location.pathname === '/setting/job_setting' ? 'rgb(0, 115, 255)' : '#6f6f6f'} />
                    <Text color={location.pathname === '/setting/job_setting' ? 'rgb(0, 115, 255)' : '#6f6f6f'} fontSize='15px'>Job Settings</Text>
                </Flex>
                {/* </Box> */}
                {/* <Box className={activeIndex === 3 ? 'active' : 'inactive'} onClick={() => handleClick(3)}> */}
                <Flex as='button' gap={3} alignItems='center' bgColor={location.pathname === '/setting/Employee_setting' ? 'white' : 'transparent'} onClick={() => navigate('/setting/Employee_setting')} my={0.5} _hover={{ backgroundColor: 'white' }} p='10px 12px' borderRadius='10px' transition='.25s' w='100%'>
                    <MdOutlineInsertChartOutlined fontSize='20' color={location.pathname === '/setting/Employee_setting' ? 'rgb(0, 115, 255)' : '#6f6f6f'} />
                    <Text color={location.pathname === '/setting/Employee_setting' ? 'rgb(0, 115, 255)' : '#6f6f6f'} fontSize='15px'>Employee Settings</Text>
                </Flex>
                {/* </Box> */}
                {/* <Flex as='button' gap={3} mt={5} alignItems='center' onClick={getLogOut}>
                    <IoLogOut fontSize='27' color='white' />
                    <Text color='#6f6f6f' fontSize='16px'>LogOut</Text>
                </Flex> */}
            </VStack>
        </>
    )
}

export default Sidebar