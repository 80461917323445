import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { GoLinkExternal } from 'react-icons/go'
import { Link } from 'react-router-dom'

const SearchComponent = ({ category, result, handleCloseSearch }) => {
    const url = '/customer'

    return (
        <Box px={2}>
            <Text fontSize='13px' fontWeight='500' color='#878d94' ml='8px'>{category}</Text>
            {result[0] && result?.map((ele, index) => <Box key={index}>
                <Link to={`${url}${ele.link}`} onClick={handleCloseSearch}>
                    <Flex _hover={{ bgColor: '#CCCCCC78' }} cursor='pointer' borderRadius={6} p='2px 8px' my={1} alignItems='center' justifyContent='space-between' onClick={() => handleCloseSearch()}>
                        <Box>
                            <Text fontSize='14px'>{ele.title}</Text>
                            <Text fontSize='13px' color='gray.500'>{ele.sub_text?.filter(val => val).join(' | ')}</Text>
                        </Box>
                        <Box p={2.5} borderRadius={6}>
                            <GoLinkExternal />
                        </Box>
                    </Flex>
                </Link>
            </Box>)}
        </Box>
    )
}

export default SearchComponent