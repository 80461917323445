import { Avatar, Flex, Text } from "@chakra-ui/react";
import React, { createContext, useState } from "react";
import FingerPrintjs from '@fingerprintjs/fingerprintjs'
import CryptoJS from "crypto-js";
import { toBlob } from "html-to-image";
import { PDFDocument } from "pdf-lib";
// Create a Context
export const JobContext = createContext();

export const JobProvider = ({ children }) => {

  const [customers, setCustomers] = useState([]);
  const [toast, setToast] = useState({
    message: "",
    type: "",
  });

  function getCookie(name) {
    const cookieArr = document.cookie.split(';');
    for (let i = 0; i < cookieArr.length; i++) {
      let cookie = cookieArr[i].trim();
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1);
      }
    }

    return null;
  }

  const fetchCustomers = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/customers/getAllCustomers`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setCustomers(json.customers);
    } else {
      setToast({
        message: json.error,
        type: "error",
      });
    }
  };
  const [customerId, setCustomerId] = useState(null);
  const fetchCustomerById = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/customers/getCustomerById?customer_id=${id}`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setCustomerId(json.customer);
    } else {
      setToast({
        message: json.error,
        type: "error",
      });
    }
  };
  const [allJobs, setAllJobs] = useState([]);

  const fetchAllJobs = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/jobs/getAllJobs`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      // console.log(json)
      setAllJobs(json.jobs);
    } else {
      setToast({
        message: json.error,
        type: "error",
      });
    }
  };
  const getJobByEmpoyee = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/jobs/getJobsByEmployee?employee_id=${id}`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setAllJobs(json.jobs);
    } else {
      setToast({
        message: json.error,
        type: "error",
      });
    }
  };
  const [jobsByCutomer, setJobsByCutomer] = useState([]);
  const fetchJobsByCustomer = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/jobs/getJobsByCustomer?customer_id=${id}`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setJobsByCutomer(json.jobs);
    } else {
      setToast({
        message: json.error,
        type: "error",
      });
    }
  };


  const [allBackup, setAllBackup] = useState([]);
  const fetchBackupDeriveAll = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/backupdrive/getAllBackupDrives`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();

    if (json.success) {
      setAllBackup(json.drives);
    } else {
      setToast({
        message: json.error,
        type: "error",
      });
    }
  };
  const [backupById, setBackupById] = useState([]);
  const fetchBackupById = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/backupdrive/getBackupDriveById?backup_drive_id=${id}`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setBackupById(json.drive);
    } else {
      setToast({
        message: json.error,
        type: "error",
      });
    }
  };
  const [allLeads, setAllLeads] = useState([]);

  // 
  const fetchLeads = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/leads/getAllLeads`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    // console.log(json)
    if (json.success) {
      setAllLeads(json.leads);
    } else {
      setToast({
        message: json.error,
        type: "error",
      });
    }
  };
  const fetchLeadsByCustomer = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/leads/getLeadsByEmployee?employee_id=${id}`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    // console.log(json)
    if (json.success) {
      setAllLeads(json.leads);
    } else {
      setToast({
        message: json.error,
        type: "error",
      });
    }
  };
  const [JobsById, setJobsById] = useState([]);
  const getJobsById = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/jobs/getJobsById?job_id=${id}`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setJobsById(json.jobs);
    } else {
      setToast({
        message: json.error,
        type: "error",
      });
    }
  };
  const [deviceTypes, setDeviceTypes] = useState([]);
  const getDeviceTypes = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/jobSettings/deviceType/getAllDeviceType`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setDeviceTypes(json.deviceTypes);
    } else {
      setToast({
        message: json.error,
        type: "error",
      });
    }
  };
  const formatDate = (timestamp, time, day) => {
    if (!timestamp) {
      return null;
    }

    const date = new Date(timestamp);
    // const now = new Date();

    // const normalizeDate = (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate());

    // const normalizedDate = normalizeDate(date);
    // const normalizedNow = normalizeDate(now);
    // const yesterday = new Date(normalizedNow.getTime() - 86400000); // Explicitly get yesterday

    const dayOfWeek = date.toLocaleString("default", { weekday: "short" });
    const dayOfMonth = date.getDate().toString().padStart(2, "0"); // Pad with 0 if necessary
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    let formattedDate = `${dayOfMonth} ${month} ${year}`;

    // if (!ignoreRelative) {
    //   if (normalizedDate.getTime() === normalizedNow.getTime()) {
    //     formattedDate = "Today";
    //   } else if (normalizedDate.getTime() === yesterday.getTime()) {
    //     formattedDate = "Yesterday";
    //   }
    // }

    if (time) {
      formattedDate += ` ${hours}:${minutes}`;
    }

    if (day) {
      formattedDate = `${dayOfWeek}, ${formattedDate}`;
    }

    return formattedDate;
  };


  const [sources, setSources] = useState([]);
  const fetchSources = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/jobSettings/sources/getAllSources`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    setSources(json.sources);
  };

  const [deviceBrands, setDeviceBrands] = useState([]);
  const fetchDeviceBrands = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/jobSettings/deviceBrand/getAllDeviceBrands`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    setDeviceBrands(json.deviceBrands);
  };

  const [deviceModels, setDeviceModels] = useState([]);
  const fetchDeviceModels = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/jobSettings/deviceModel/getAllDeviceModels`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    setDeviceModels(json.deviceModels);
  };

  const [accessories, setAccessories] = useState([]);
  const fetchAccessories = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/jobSettings/accessories/getAllAccessories`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    setAccessories(json.accessories);
  };

  const [deviceColors, setDeviceColors] = useState([]);
  const fetchDeviceColors = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/jobSettings/deviceColor/getAllDeviceColors`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    setDeviceColors(json.device_colors);
  };

  const [services, setServices] = useState([]);
  const fetchServices = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/jobSettings/services/getAllServices`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    setServices(json.services);
  };
  const [status, setStatus] = useState([]);
  const fetchStatus = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/jobSettings/statuses/getAllStatus`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    setStatus(json.statuses);
  };

  const getStatusColor = (value) => {
    // if (!status || !value) return null;
    const matchedStatus = status.find((ele) => ele.main_status === value);
    const bgColor = matchedStatus ? matchedStatus.color : "grey";
    // console.log(bgColor)
    return (
      <Text
        as="button"
        bg={bgColor}
        color="#fff"
        p="3px 10px"
        borderRadius="5px"
        border={`1px solid ${bgColor}`}
      >
        {value}
      </Text>
    );
  };

  const getStatusColorById = (value) => {
    // if (!status || !value) return null;
    const matchedStatus = status.find((ele) => ele.id === value);
    const bgColor = matchedStatus ? matchedStatus.color : "black";
    // console.log(bgColor)
    return (
      <Text
        as="button"
        bg={bgColor}
        color="#fff"
        fontSize={"12px"}
        p="3px 10px"
        borderRadius="5px"
        border={`1px solid ${bgColor}`}
      >
        {matchedStatus?.main_status}
      </Text>
    );
  };

  const [backupsByJob, setBackupsByJob] = useState([]);
  const getBackupsByJob = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/jobs/getBackupsByJob?job_id=${id}`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setBackupsByJob(json.backups);
    } else {
      setToast({
        message: json.error,
        type: "error",
      });
    }
  };
  const [Employees, setEmployees] = useState([]);
  const getAllEmployees = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/employee/employee/getAllEmployees`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setEmployees(json.employees);
    } else {
      setToast({
        message: json.error,
        type: "error",
      });
    }
  };

  const [jobDrives, setJobDrives] = useState([]);
  const getAllJobDrives = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/backupdrive/getProgressBar`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setJobDrives(json.drive);
    } else {
      setToast({
        message: json.error,
        type: "error",
      });
    }
  };

  const [allTax, setAllTax] = useState([]);
  const fetchTax = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/generalSettings/taxSetting/getTaxSetting`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      const data = await response.json();
      setAllTax(data.settings);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [serviceByJob, setServiceByJob] = useState([]);
  const getServiceByJob = async (job_id) => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/jobs/getQuotationServiceByJob?job_id=${job_id}`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const data = await response.json();
    setServiceByJob(data.services);
  };

  const [allParts, setAllParts] = useState([]);
  const getAllParts = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/jobSettings/parts/getAllParts`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      const data = await response.json();
      setAllParts(data.parts);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [quotationPartByJob, setQuotationPartByJob] = useState([]);
  const getQuotationPartByJob = async (job_id) => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/jobs/getQuotationPartByJob?job_id=${job_id}`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const data = await response.json();
    setQuotationPartByJob(data.services);
  };
  const [invoicePartByJob, setInvoicePartByJob] = useState([]);
  const getInvoicePartByJob = async (job_id) => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/jobs/getInvoicePartByJob?job_id=${job_id}`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const data = await response.json();
    setInvoicePartByJob(data.services);
  };

  const [invoiceServiceByJob, setInvoiceServiceByJob] = useState([]);
  const getInvoiceServiceByJob = async (job_id) => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/jobs/getInvoiceServiceByJob?job_id=${job_id}`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const data = await response.json();
    setInvoiceServiceByJob(data.services);
  };

  const [pickupDrops, setPickupDrops] = useState([]);

  const getAllPickUpDrop = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/pickupDrops/getAllPickUpDrop`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const data = await response.json();
    if (data.success) {
      setPickupDrops(data.pickupdrops);
    } else {
      console.log(data.error);
    }
  };

  const getPickUpDropByEmployee = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/pickupDrops/getPickUpDropByEmployee?employee_id=${id}`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const data = await response.json();
    if (data.success) {
      setPickupDrops(data.pickupdrops);
    } else {
      console.log(data.error);
    }
  };
  const [activities, setActivities] = useState([]);
  const getActivityByJob = async (job_id) => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/jobs/getActivityByJob?job_id=${job_id}`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const data = await response.json();
    if (data.success) {
      setActivities(data.activities);
    } else {
      console.log(data.error);
    }
  };

  const [cusActivities, setCusActivities] = useState([])
  const getCusActivityByJob = async (job_id) => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/jobs/getCustomerActivityByJob?job_id=${job_id}`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const data = await response.json();
    if (data.success) {
      setCusActivities(data.activities);
    } else {
      console.log(data.error);
    }
  };

  const [allLeadStatus, setAllLeadStatus] = useState([]);
  const getAllLeadStatus = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/jobSettings/leadStatus/getAllLeadStatus`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const data = await response.json();

    if (data.success) {
      setAllLeadStatus(data.statuses);
    } else {
      console.log(data.error);
    }
  };

  const getLeadStatus = (value) => {
    const matchedStatus = allLeadStatus.find(
      (ele) => ele.lead_status === value
    );
    const bgColor = matchedStatus ? matchedStatus.color : "#007bff";
    return (
      <Text
        as="button"
        bg={bgColor}
        color="#fff"
        p="3px 10px"
        borderRadius="5px"
        border={`1px solid ${bgColor}`}
      >
        {matchedStatus?.lead_status}
      </Text>
    );
  };

  const [comment, setComment] = useState([]);
  const fetchComment = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/jobSettings/quickReplies/getAllQuickReplies`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();

    setComment(json.quick_replies);
  };

  const convertDataSize = (data_size) => {
    const mb = parseInt(data_size);
    if (mb < 1024) {
      return `${mb} MB`;
    } else if (mb < 1024 ** 2) {
      const gb = mb / 1024;
      return `${gb.toFixed(2)} GB`;
    } else if (mb < 1024 ** 3) {
      const tb = mb / 1024 ** 2;
      return `${tb.toFixed(2)} TB`;
    } else {
      const pb = mb / 1024 ** 3;
      return `${pb.toFixed(2)} PB`;
    }
  };

  const [pickupStatus, setPickupStatus] = useState([]);
  const fetchPickupStatus = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/jobSettings/pickupStatus/getAllPickupStatus`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    setPickupStatus(json.statuses);
  };

  const [dashCounts, setDashCounts] = useState(null);
  const getDashboardCounts = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/dashboard/getCounts`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    setDashCounts(json.data);
  };

  const getPickupStatus = (value) => {
    const matchedStatus = pickupStatus.find(
      (ele) => ele.pickup_status === value
    );
    const bgColor = matchedStatus ? matchedStatus.color : "#007bff";
    return (
      <Text
        as="button"
        bg={bgColor}
        color="#fff"
        p="3px 10px"
        borderRadius="5px"
        border={`1px solid ${bgColor}`}
      >
        {matchedStatus?.pickup_status}
      </Text>
    );
  };

  const [paymentsByJobId, setPaymentsByJobId] = useState([]);
  const getPaymentsByJobId = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/jobs/getPaymentsByJobId?job_id=${id}`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setPaymentsByJobId(json.payments);
    } else {
      setToast({
        message: json.error,
        type: "error",
      });
    }
  };
  const [deliveriesByJobId, setDeliveriesByJobId] = useState([]);
  const getDeliveriesByJobId = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/jobs/getDeliveriesByJobId?job_id=${id}`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setDeliveriesByJobId(json.deliveries);
    } else {
      setToast({
        message: json.error,
        type: "error",
      });
    }
  };

  const convertDataSizeToMb = (dataSize, unit) => {
    let mb;
    switch (unit.toUpperCase()) {
      case "GB":
        mb = dataSize * 1024; // Convert GB to MB
        break;
      case "TB":
        mb = dataSize * 1024 * 1024; // Convert TB to MB
        break;
      case "PB":
        mb = dataSize * 1024 * 1024 * 1024; // Convert PB to MB
        break;
      default:
        mb = dataSize; // Assume the input is already in MB
    }
    return `${mb}`;
  };

  const [jobNotes, setJobNotes] = useState([]);
  const getJobNotes = async (job_id, employee_id) => {
    // console.log(job_id)
    // console.log(employee_id)
    // console.log(`notes/getJobNotesByJob?job_id=${job_id}&employee_id=${employee_id}`)
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/notes/getJobNotesByJob?job_id=${job_id}&employee_id=${employee_id}`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setJobNotes(json.notes);
    } else {
      setToast({
        message: json.error,
        type: "error",
      });
    }
  };

  const [customerNotes, setCustomerNotes] = useState([])
  const getCustomerNotes = async (customer_id, employee_id) => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/notes/getCustomerNotesByCustomer?customer_id=${customer_id}&employee_id=${employee_id}`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setCustomerNotes(json.notes);
    } else {
      setToast({
        message: json.error,
        type: "error",
      });
    }
  };

  const [sequenceSetting, setSequenceSetting] = useState([]);
  const getSequenceSettings = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/generalSettings/sequenceSetting/getSequenceSetting`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setSequenceSetting(json.settings);
    } else {
      setToast({
        message: json.error,
        type: "error",
      });
    }
  };

  const PriorityData = [
    { value: "Regular", label: "Regular" },
    { value: "Urgent", label: "Urgent" },
    { value: "Critical", label: "Critical" },
  ];

  const PriorityDatas = PriorityData?.map((element) => {
    return {
      label: (
        <Flex alignItems="center" gap={2}>
          <Text fontSize={"12px"}>{element.label}</Text>
        </Flex>
      ),
      value: element.label,
    };
  });
  const JobTypeOne = [
    { value: "No Warranty", label: "No Warranty" },
    { value: "Warranty", label: "Warranty" },
    { value: "Return", label: "Return" },
  ];

  const JobTypeOnes = JobTypeOne?.map((element) => {
    return {
      label: (
        <Flex alignItems="center" gap={2}>
          <Text fontSize={"12px"}>{element.label}</Text>
        </Flex>
      ),
      value: element.value,
    };
  });
  const CustomerType = [
    { value: "End User", label: "End User" },
    { value: "Dealer", label: "Dealer" },
    { value: "Corporate User", label: "Corporate User" },
  ];

  const CustomerTypes = CustomerType?.map((element) => {
    return {
      label: (
        <Flex alignItems="center" gap={2}>
          <Text fontSize={"12px"}>{element.label}</Text>
        </Flex>
      ),
      value: element.label,
    };
  });
  const LeadType = [
    { value: "End User", label: "End User" },
    { value: "Dealer", label: "Dealer" },
    { value: "Corporate User", label: "Corporate User" },
  ];

  const LeadTypes = LeadType?.map((element) => {
    return {
      label: (
        <Flex alignItems="center" gap={2}>
          <Text fontSize={"12px"}>{element.label}</Text>
        </Flex>
      ),
      value: element.label,
    };
  });

  const [fingerPrint, setFingerPrint] = useState(null)
  const getDeviceFingerprint = async () => {
    const fingerprint = await FingerPrintjs.load()
    const fp = await fingerprint.get()
    setFingerPrint({ device_id: fp.visitorId, device_info: navigator.userAgent })
  };

  const parseUserAgent = (userAgent) => {
    const osRegex = /\(([^)]+)\)/;
    const browserRegex = /(Chrome\/[\d.]+)/;
    const engineRegex = /(AppleWebKit\/[\d.]+)/;

    const osMatch = userAgent.match(osRegex);
    const browserMatch = userAgent.match(browserRegex);
    const engineMatch = userAgent.match(engineRegex);

    let osInfo = '';
    let browserInfo = '';
    let engineInfo = '';

    if (osMatch) {
      osInfo = osMatch[1];
      // Check for 64-bit Windows
      if (osInfo.includes('Win64; x64')) {
        osInfo = 'Windows 10 (64-bit)';
      }
    }

    if (browserMatch) {
      browserInfo = browserMatch[0].replace('/', ', version ');
    }

    if (engineMatch) {
      engineInfo = engineMatch[0];
    }

    return {
      os: osInfo,
      browser: browserInfo,
      engine: engineInfo,
    };
  };

  const encrypt = (dataStr) => {
    if (!process.env.REACT_APP_CRYPTO_KEY) {
      throw new Error('Encryption key is not set in environment variables');
    }
    try {
      // Encrypt the data
      const ciphertext = CryptoJS.AES.encrypt(dataStr, process.env.REACT_APP_CRYPTO_KEY).toString();
      // Convert to URL-safe base64
      const urlSafeCiphertext = ciphertext.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
      return urlSafeCiphertext;
    } catch (error) {
      console.error('Error encrypting data:', error);
      throw new Error('Encryption failed');
    }
  }

  const decrypt = async (urlSafeCiphertext) => {
    if (!process.env.REACT_APP_CRYPTO_KEY) {
      throw new Error('Decryption key is not set in environment variables');
    }

    try {
      // Convert from URL-safe base64
      let base64Ciphertext = urlSafeCiphertext.replace(/-/g, '+').replace(/_/g, '/');
      // Add padding back if necessary
      while (base64Ciphertext.length % 4 !== 0) {
        base64Ciphertext += '=';
      }
      // Decrypt the data
      const bytes = CryptoJS.AES.decrypt(base64Ciphertext, process.env.REACT_APP_CRYPTO_KEY);
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      const jsonData = JSON.parse(decryptedData)
      if (jsonData?.time < Date.now()) {
        return { message: 'The link has been expired', is_expired: true }
      }
      const response = await fetch(
        `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/admin/checkUserDeviceStatus?employee_id=${jsonData?.employee_id}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      const json = await response.json();
      if (json.success) {
        return { decryptedData, device_status: json.device_approved, message: 'user fetched', user_details: json.user };
      } else {
        return { json };
      }
    } catch (error) {
      console.error('Error decrypting data:', error);
      throw new Error('Decryption failed');
    }
  }

  const decryptRemote = async (urlSafeCiphertext) => {
    if (!process.env.REACT_APP_CRYPTO_KEY) {
      throw new Error('Decryption key is not set in environment variables');
    }

    try {
      // Convert from URL-safe base64
      let base64Ciphertext = urlSafeCiphertext.replace(/-/g, '+').replace(/_/g, '/');
      // Add padding back if necessary
      while (base64Ciphertext.length % 4 !== 0) {
        base64Ciphertext += '=';
      }
      // Decrypt the data
      const bytes = CryptoJS.AES.decrypt(base64Ciphertext, process.env.REACT_APP_CRYPTO_KEY);
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      const jsonData = JSON.parse(decryptedData)
      if (jsonData?.time < Date.now()) {
        return { message: 'The link has been expired', is_expired: true }
      }
      const response = await fetch(`${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/jobs/checkRemoteStatus?job_id=${jsonData?.job_id}`);
      const json = await response.json();
      if (json.success) {
        return { decryptedData, remote_status: json.remote_status, message: 'job fetched', jsonData };
      } else {
        return { json };
      }
    } catch (error) {
      console.error('Error decrypting data:', error);
      throw new Error('Decryption failed');
    }
  }

  const decryptActivate = async (urlSafeCiphertext) => {
    if (!process.env.REACT_APP_CRYPTO_KEY) {
      throw new Error('Decryption key is not set in environment variables');
    }

    try {
      // Convert from URL-safe base64
      let base64Ciphertext = urlSafeCiphertext.replace(/-/g, '+').replace(/_/g, '/');
      // Add padding back if necessary
      while (base64Ciphertext.length % 4 !== 0) {
        base64Ciphertext += '=';
      }
      // Decrypt the data
      const bytes = CryptoJS.AES.decrypt(base64Ciphertext, process.env.REACT_APP_CRYPTO_KEY);
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      const jsonData = JSON.parse(decryptedData)
      const response = await fetch(`${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/customers/checkAccountStatus?customer_id=${jsonData?.customer_id}`);
      const json = await response.json();
      if (json.success) {
        return { decryptedData, account_status: json.account_status, message: 'customer fetched', jsonData };
      } else {
        return { json };
      }
    } catch (error) {
      console.error('Error decrypting data:', error);
      throw new Error('Decryption failed');
    }
  }

  // const decryptOnly = (urlSafeCiphertext) => {
  //   // Convert from URL-safe base64
  //   let base64Ciphertext = urlSafeCiphertext.replace(/-/g, '+').replace(/_/g, '/');
  //   // Add padding back if necessary
  //   while (base64Ciphertext.length % 4 !== 0) {
  //     base64Ciphertext += '=';
  //   }
  //   // Decrypt the data
  //   const bytes = CryptoJS.AES.decrypt(base64Ciphertext, process.env.REACT_APP_CRYPTO_KEY);
  //   const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  //   if (!decryptedData) {
  //     return { error: 'Invalid Url' }
  //   }
  //   const jsonData = JSON.parse(decryptedData)
  //   return jsonData

  // }
  const decryptOnly = (urlSafeCiphertext) => {
    // Convert from URL-safe base64

    try {
      // Add padding back if necessary
      // console.log(urlSafeCiphertext);

      let base64Ciphertext = urlSafeCiphertext.replace(/-/g, '+').replace(/_/g, '/');
      while (base64Ciphertext.length % 4 !== 0) {
        base64Ciphertext += '=';
      }
      // Decrypt the data
      const bytes = CryptoJS.AES.decrypt(base64Ciphertext, process.env.REACT_APP_CRYPTO_KEY);
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      if (!decryptedData) {
        return { error: 'Invalid Url' }
      }
      const jsonData = JSON.parse(decryptedData)
      return jsonData

    } catch (error) {
      console.log(error.message);

    }

  }




  const FollowUp = [
    { value: "Call", label: "Call" },
    { value: "WhatsApp", label: "WhatsApp" },
    { value: "Email", label: "Email" },
    { value: "SMS", label: "SMS" },
  ];

  const FollowUpS = FollowUp?.map((element) => {
    return {
      label: (
        <Flex alignItems="center" gap={2}>
          <Text fontSize={"12px"}>{element.label}</Text>
        </Flex>
      ),
      value: element.label,
    };
  });


  const PickUpDrop = [
    { value: "Pickup", label: "Pickup" },
    { value: "Drop", label: "Drop" },
  ];

  const PickUpDrops = PickUpDrop?.map((element) => {
    return {
      label: (
        <Flex alignItems="center" gap={2}>
          <Text fontSize={"12px"}>{element.label}</Text>
        </Flex>
      ),
      value: element.label,
    };
  });

  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return "";
    const date = new Date(dateTimeString);
    const timeZoneOffset = date.getTimezoneOffset() * 60000;
    const adjustedDate = new Date(date.getTime() - timeZoneOffset);
    const year = adjustedDate.getFullYear();
    const month = String(adjustedDate.getMonth() + 1).padStart(2, "0");
    const day = String(adjustedDate.getDate()).padStart(2, "0");
    const hours = String(adjustedDate.getHours()).padStart(2, "0");
    const minutes = String(adjustedDate.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const [banners, setBanners] = useState([])
  const fetchLoginBanners = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/loginBanner/getAllBanners`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setBanners(json.banners);
    } else {
      setToast({
        message: json.error,
        type: "error",
      });
    }
  };

  const [pickUpDropByCustomer, setPickUpDropByCustomer] = useState([])
  const getPickUpDropByCustomer = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/pickupDrops/getPickUpDropByCustomer?customer_id=${id}`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setPickUpDropByCustomer(json.pickupdrops);
    } else {
      setToast({
        message: json.error,
        type: "error",
      });
    }
  };
  const [invoicesByCustomer, setInvoicesByCustomer] = useState([])
  const getInvoicesByCustomer = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/jobs/getInvoicesByCustomer?customer_id=${id}`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setInvoicesByCustomer(json.invoices);
    } else {
      setToast({
        message: json.error,
        type: "error",
      });
    }
  };

  const ServiceType = [
    { value: "Carried by User", label: "Carried by User" },
    { value: "Courier", label: "Courier" },
    { value: "On Site", label: "On Site" },
    { value: "Pickup", label: "Pickup" },
  ];

  const ServiceTypes = ServiceType?.map((element) => {
    return {
      label: (
        <Flex alignItems="center" gap={2}>
          <Text fontSize={"12px"}>{element.label}</Text>
        </Flex>
      ),
      value: element.value,
    };
  });

  const JobType = [
    { value: "No Warranty", label: "No Warranty" },
    { value: "Warranty", label: "Warranty" },
    { value: "Return", label: "Return" },
  ];

  const JobTypes = JobType?.map((element) => {
    return {
      label: (
        <Flex alignItems="center" gap={2}>
          <Text fontSize={"12px"}>{element.label}</Text>
        </Flex>
      ),
      value: element.label,
    };
  });
  const EmployeeRole = [
    { value: "Technician", label: "Technician" },
    { value: "Delivery", label: "Delivery" },
    { value: "Reception", label: "Reception" },
    { value: "Marketing", label: "Marketing" },
  ];

  const EmployeeRoles = EmployeeRole?.map((element) => {
    return {
      label: (
        <Flex alignItems="center" gap={2}>
          <Text fontSize={"12px"}>{element.label}</Text>
        </Flex>
      ),
      value: element.label,
    };
  });

  const paymentStatus = [
    { value: "Partially Paid", label: "Partially Paid" },
    { value: "Paid", label: "Paid" },
    { value: "Unpaid", label: "Unpaid" },
  ];

  const paymentStatuss = paymentStatus?.map((element) => {
    return {
      label: (
        <Flex alignItems="center" gap={2}>
          <Text fontSize={"12px"}>{element.label}</Text>
        </Flex>
      ),
      value: element.label,
    };
  });

  const [empNotifications, setEmpNotifications] = useState([])
  const getEmployeeNotificationsById = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/notification/getEmployeeNotificationsById?employee_id=${id}`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setEmpNotifications(json.notification);
    } else {
      setToast({
        message: json.error,
        type: "error",
      });
    }
  };

  const groupNotificationsByDay = (notifications) => {
    const grouped = {};

    const isToday = (date) => {
      const today = new Date();
      return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
      );
    };

    const isYesterday = (date) => {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return (
        date.getDate() === yesterday.getDate() &&
        date.getMonth() === yesterday.getMonth() &&
        date.getFullYear() === yesterday.getFullYear()
      );
    };

    const formatDate = (date) => {
      return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    };

    notifications.forEach((notification) => {
      const createdAt = new Date(notification.created_at);

      let dayKey;
      if (isToday(createdAt)) {
        dayKey = 'today';
      } else if (isYesterday(createdAt)) {
        dayKey = 'yesterday';
      } else {
        dayKey = formatDate(createdAt); // For older dates (e.g., August 17, 2024)
      }

      if (!grouped[dayKey]) {
        grouped[dayKey] = [];
      }

      grouped[dayKey].push(notification);
    });

    return Object.keys(grouped).map((day) => ({
      day,
      notifications: grouped[day],
    }));
  };

  const [jobSettings, setJobSettings] = useState(null)
  const getDefaultJobSettings = async () => {
    const response = await fetch(`${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/generalSettings/basicTearms/getDefaultJobSettings`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setJobSettings(json.settings);
    } else {
      setToast({
        message: json.error,
        type: "error",
      });
    }
  };

  const [businessTab, setBusinessTab] = useState(null)
  const fetchBusinessTabs = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/businessInfo/business/getBusinessInfo`,
      {
        headers: { Authorization: localStorage.getItem("token") },
      }
    );
    const json = await response.json();
    if (json.success) {
      setBusinessTab(json.details)
    } else {

    }
  };

  const getAssignee = (employees, employee_id) => {
    const filteredEmployee = employees?.filter(element => element.id === employee_id)[0]
    return (
      <Flex gap={2} alignItems='center'>
        <Avatar name={filteredEmployee?.name} w={'20px'} h='20px' size='xs' />
        <Text fontSize='12px' fontWeight='600'>{filteredEmployee?.name} </Text>
      </Flex>
    )
  }

  const [bankDetails, setBankDetails] = useState(null)
  const fetchBankDetails = async () => {
    const response = await fetch(`${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/businessInfo/business/getBankDetails`, {
      headers: { Authorization: localStorage.getItem('token') }
    })
    const json = await response.json();
    if (json.success) {
      setBankDetails(json.details)
    } else {

    }
  }

  function formatToINR(number, short = false) {
    const isNegative = number < 0;
    const absNumber = Math.abs(number);

    if (short) {
      if (absNumber >= 1e7) {
        return (isNegative ? "-" : "") + (absNumber / 1e7).toFixed(2) + " Cr";
      } else if (absNumber >= 1e5) {
        return (isNegative ? "-" : "") + (absNumber / 1e5).toFixed(2) + " L";
      } else if (absNumber >= 1e3) {
        return (isNegative ? "-" : "") + (absNumber / 1e3).toFixed(2) + " K";
      } else {
        return (isNegative ? "-" : "") + absNumber.toString();
      }
    } else {
      return (
        (isNegative ? "-" : "") +
        new Intl.NumberFormat("en-IN", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }).format(absNumber)
      );
    }
  }




  const fileToArrBuffer = (file) =>
    new Promise((res, rej) => {
      const fileReader = new FileReader();
      fileReader.onload = () => res(fileReader.result);
      fileReader.onerror = () => rej(fileReader.error);
      fileReader.readAsArrayBuffer(file);
    });

  // Download PDF
  const downloadFile = (blob) => {
    const URLs = URL.createObjectURL(blob);
    const el = document.createElement('a');
    el.download = 'your_file.pdf';
    el.href = URLs;
    el.click();
    URL.revokeObjectURL(URLs);
  };

  const downloadHTML = async (ref) => {
    const dataURL = await toBlob(ref, { cacheBust: false });
    const buffer = await fileToArrBuffer(dataURL);
    const pdfDoc = await PDFDocument.create();
    let image;

    if (/jpe?g/i.test(dataURL.type)) {
      image = await pdfDoc.embedJpg(buffer);
    } else if (/png/i.test(dataURL.type)) {
      image = await pdfDoc.embedPng(buffer);
    } else {
      throw new Error('Please choose a JPEG or PNG file to proceed');
    }

    const page = pdfDoc.addPage();
    const scaledImage = image.scale(0.5);
    const x = 8; // reduce this for minimal left margin
    const y = page.getHeight() - scaledImage.height - 10;
    page.drawImage(image, {
      width: scaledImage.width,
      height: scaledImage.height,
      x: x,
      y: y,
    });

    // Save PDF and download
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    downloadFile(blob);
  }

  const [jobPayments, setJobPayments] = useState([]);
  const fetchJobPayments = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/reports/getAllJobPayments`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setJobPayments(json.payments);
    } else {
      setToast({
        message: json.error,
        type: "error",
      });
    }
  };

  const [cusNotifications, setCusNotifications] = useState([])
  const getCustomerNotificationsById = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/notification/getCustomerNotificationsById?customer_id=${id}`,
      {
        headers: {
          Authorization: getCookie("token") || localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setCusNotifications(json.notification);
    } else {
      setToast({
        message: json.error,
        type: "error",
      });
    }
  };

  const stripHtmlTags = (htmlContent) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  return (
    <JobContext.Provider
      value={{
        getJobByEmpoyee,
        fetchLeadsByCustomer,
        getPickUpDropByEmployee,
        paymentStatuss,
        JobTypeOnes,
        EmployeeRoles,
        invoicesByCustomer,
        ServiceTypes,
        getInvoicesByCustomer,
        pickUpDropByCustomer,
        PickUpDrops,
        getPickUpDropByCustomer,
        LeadTypes,
        CustomerTypes,
        customers,
        toast,
        setToast,
        fetchCustomers,
        customerId,
        fetchCustomerById,
        jobsByCutomer,
        backupsByJob,
        getBackupsByJob,
        fetchJobsByCustomer,
        fetchAllJobs,
        allJobs,
        getStatusColor,
        fetchBackupDeriveAll,
        allBackup,
        fetchBackupById,
        backupById,
        fetchLeads,
        allLeads,
        getJobsById,
        JobsById,
        deviceTypes,
        getDeviceTypes,
        formatDate,
        sources,
        fetchSources,
        deviceBrands,
        fetchDeviceBrands,
        fetchDeviceModels,
        deviceModels,
        fetchAccessories,
        accessories,
        fetchDeviceColors,
        deviceColors,
        services,
        fetchServices,
        fetchStatus,
        status,
        getAllEmployees,
        Employees,
        getAllJobDrives,
        jobDrives,
        fetchTax,
        allTax,
        getServiceByJob,
        serviceByJob,
        allParts,
        getAllParts,
        getQuotationPartByJob,
        quotationPartByJob,
        getInvoiceServiceByJob,
        invoiceServiceByJob,
        invoicePartByJob,
        getInvoicePartByJob,
        getAllPickUpDrop,
        pickupDrops,
        convertDataSizeToMb,
        getAllLeadStatus,
        allLeadStatus,
        fetchComment,
        getLeadStatus,
        deliveriesByJobId,
        getDeliveriesByJobId,
        comment,
        getActivityByJob,
        activities,
        getStatusColorById,
        convertDataSize,
        fetchPickupStatus,
        pickupStatus,
        getDashboardCounts,
        getPaymentsByJobId,
        paymentsByJobId,
        dashCounts,
        getPickupStatus,
        getJobNotes,
        jobNotes,
        getSequenceSettings,
        sequenceSetting,
        PriorityDatas,
        JobTypes,
        getCustomerNotes,
        customerNotes,
        getDeviceFingerprint,
        fingerPrint,
        parseUserAgent,
        encrypt,
        decrypt,
        formatDateTime,
        FollowUpS,
        fetchLoginBanners,
        banners,
        decryptOnly,
        getEmployeeNotificationsById,
        empNotifications,
        groupNotificationsByDay,
        getDefaultJobSettings,
        jobSettings,
        fetchBusinessTabs,
        businessTab,
        fetchBankDetails,
        bankDetails,
        decryptRemote,
        getAssignee,
        formatToINR,
        getCookie,
        fetchJobPayments,
        jobPayments,
        downloadHTML,
        getCustomerNotificationsById,
        cusNotifications,
        getCusActivityByJob,
        cusActivities,
        stripHtmlTags,
        decryptActivate
      }}
    >
      {children}
    </JobContext.Provider>
  );
};
