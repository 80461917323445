import React, { startTransition, useContext, useEffect, useRef, useState } from "react";
import {
  HStack,
  Flex,
  Box,
  Image,
  Text,
  Stack,
  InputGroup,
  InputLeftAddon,
  Input,
  useDisclosure,
  Button,
  Tabs,
  TabList,
  Tab,
  TabIndicator,
  TabPanels,
  TabPanel,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
  FormControl,
  FormLabel,
  IconButton,
  InputRightElement,
  InputLeftElement,
  Divider,
  Spinner,
  AccordionIcon,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Accordion,
} from "@chakra-ui/react";
import Logo from "../assets/Admin/logo.png";
import { FaRegBell } from "react-icons/fa";
import { IoSearchOutline } from "react-icons/io5";
import { Avatar } from "@chakra-ui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { JobContext } from "../context/JobContext";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { CloseIcon, ExternalLinkIcon, HamburgerIcon, SearchIcon } from "@chakra-ui/icons";
import SearchComponent from "./SearchComponent";

import { FiUsers } from "react-icons/fi";
import { LuCalendarDays } from "react-icons/lu";
import { TbEdit } from "react-icons/tb";
import { MdOutlineInsertChartOutlined } from "react-icons/md";
import ReactHtmlParser from "html-react-parser";
import avatar_logo from '../assets/Admin/avatar.png'

function Navbar({ showAlert }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isChangeOpen, onOpen: onChangeOpen, onClose: onChangeClose } = useDisclosure();
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const { isOpen: isSearchOpen, onOpen: onSearchOpen, onClose: onSearchClose } = useDisclosure();
  const { isOpen: isMenuOpen, onOpen: onMenuOpen, onClose: onMenuClose } = useDisclosure();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);


  const {
    groupNotificationsByDay,
    formatDate, decryptOnly
  } = useContext(JobContext);

  const navigate = useNavigate();
  const data = localStorage.getItem("token");
  const encrypted = decryptOnly(localStorage.getItem('user'));
  useEffect(() => {
    if (encrypted && encrypted?.user_id) {
      getEmployeeNotificationsById(encrypted?.user_id);
      fetchEmployeeDetails();
      const intervalId = setInterval(() => {
        getEmployeeNotificationsById(encrypted?.user_id);
      }, 30000);

      return () => clearInterval(intervalId);
    }
  }, []);

  function filterByAssignee(data, assigneeId) {
    if (data) {
      return data.map(category => {
        // Check if any result in the category has the 'assignee' property
        if (category?.result?.some(item => 'assignee' in item)) {
          // Return the category with filtered results based on the assignee
          return {
            ...category,
            result: category?.result?.filter(item => item.assignee === assigneeId)
          };
        }
        // If 'assignee' doesn't exist in the category, return it unchanged
        return category;
      });
    } else {
      return []
    }
  }

  const [empNotifications, setEmpNotifications] = useState([])
  const notificationsRef = useRef(empNotifications);
  const getEmployeeNotificationsById = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/notification/getEmployeeNotificationsById?employee_id=${id}`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    notificationsRef.current = json
    if (json.success && JSON.stringify(notificationsRef.current) !== JSON.stringify(json.notification)) {
      notificationsRef.current = json.notification;
      setEmpNotifications(json.notification);
    }
  };

  const location = useLocation();
  const isAdminPath = location.pathname.toLowerCase().startsWith("/admin");
  const unregisterServiceWorker = async () => {
    if ("serviceWorker" in navigator) {
      const registrations = await navigator.serviceWorker.getRegistrations();
      for (const registration of registrations) {
        await registration.unregister();
        console.log("Service Worker unregistered");
      }
    }
  };
  const sortedNotification = groupNotificationsByDay(empNotifications);
  const seenNotifications = groupNotificationsByDay(
    empNotifications?.filter((element) => element.seen === "1")
  );
  const unSeenNotifications = groupNotificationsByDay(
    empNotifications?.filter((element) => element.seen === "0")
  );


  const markSeen = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/notification/markEmployeeNotificationSeen`,
      {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ notification_id: id }),
      }
    );
    const json = await response.json();
    if (json.success) {
      getEmployeeNotificationsById(encrypted.user_id);
    } else {
      showAlert(json.error, "error");
    }
  };

  const [loading, setLoading] = useState(false);
  const markAllSeen = async () => {
    setLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/notification/markAllEmployeeNotificationSeen`,
      {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ employee_id: encrypted.user_id }),
      }
    );
    const json = await response.json();
    if (json.success) {
      setLoading(false);
      getEmployeeNotificationsById(encrypted.user_id);
    } else {
      setLoading(false);
      showAlert(json.error, "error");
    }
  };

  const [userDetails, setUserDetails] = useState(null)
  const fetchEmployeeDetails = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/employee/employee/getEmployeeById?employee_id=${encrypted.user_id}`,
        {
          headers: { Authorization: localStorage.getItem("token") },
        }
      );
      const json = await response.json();
      if (json.success) {
        // console.log(json.employees)
        setUserDetails(json.employees);
      }
    } catch (error) {
      console.error("Error fetching employee details:", error);
    }
  };


  const NotificationElement = ({ element, onClose }) => {
    return (
      <Box
        bgColor={element.seen === "0" ? "#5a82dc3b" : "#fafbff"}
        p={2}
        borderTop="1px solid #ccc"
        borderBottom="1px solid #ccc"
        cursor="pointer"
        _hover={{ backgroundColor: "#0000001c" }}
        transition="0.25s"
        onClick={() => markSeen(element.id)}
      >
        <Flex gap={2} px={3} position="relative" alignItems="center">
          <Box>
            <Avatar size="md" name="Data Care" src={avatar_logo} />
          </Box>
          <Box w='100%'>
            <Text fontSize="14px" fontWeight="500">
              {element?.topic}
            </Text>
            <Text fontSize="12px">{element?.content && ReactHtmlParser(element?.content)}</Text>
            <Flex justifyContent='space-between' alignItems='center'>
              <Text fontSize="11px" color="#807f7f">
                {formatDate(element?.created_at, true)}
              </Text>
              {element.links ? <Link to={element.links} onClick={onClose}>
                <Button size='xs' rightIcon={<ExternalLinkIcon fontSize='13px' />} colorScheme='blackAlpha' variant='outline'>
                  Open
                </Button>
              </Link> : <Text w='max-content'></Text>}
            </Flex>
          </Box>
          <Box
            position="absolute"
            top="50%"
            left="-4px"
            width="7px"
            height="7px"
            borderRadius={5}
            bgColor={element.seen === "0" ? "#5a82dc" : "transparent"}
          ></Box>
        </Flex>
      </Box>
    );
  };

  const [creds, setCreds] = useState({
    current_password: "",
    new_password: "",
    confirm_password: ""
  })

  const handleOnChange = (e) => {
    setCreds({ ...creds, [e.target.name]: e.target.value })
  }

  const handleChangePassword = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/admin/changePassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ employee_id: userDetails?.id, current_password: creds.current_password, new_password: creds.new_password })
      })
      const json = await response.json();
      if (json.success) {
        showAlert(json.success, 'success')
        setLoading(false);
        onChangeClose();
        setCreds({
          current_password: "",
          new_password: "",
          confirm_password: ""
        })
      } else {
        showAlert(json.error, 'error')
        setLoading(false);
      }
    } catch (error) {
      showAlert('Internal server error', 'error')
      setLoading(false);
    }
  }

  const [searchResults, setSearchResults] = useState([])

  const [searchQuery, setSearchQuery] = useState('')
  const globalSearch = async () => {
    try {
      if (searchQuery === '') {
        return;
      }
      const response = await fetch(`${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/reports/search?query=${searchQuery}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token")
        },
      })
      const json = await response.json();
      if (json.success) {
        if (encrypted?.user_type === 'admin') {
          setSearchResults(json.searchResults)
        } else {
          setSearchResults(filterByAssignee(json.searchResults, encrypted?.user_id))
        }
        setLoading(false)
      } else {
        console.log(json.error);
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      showAlert("Internal server error", 'error')
    }
  }

  useEffect(() => {
    if (searchQuery) {
      setLoading(true)
    }
    const debounceFetch = setTimeout(globalSearch, 300);

    return () => {
      return () => clearTimeout(debounceFetch);
    }
  }, [searchQuery])

  const handleLogout = () => {
    startTransition(() => {
      localStorage.removeItem(data);
      unregisterServiceWorker();
      navigate("/admin");
    });
  };

  return (
    <>
      <HStack
        p={{
          xl: "0px 20px",
          lg: "0px 20px",
          md: "0px 20px",
          sm: "0px 5px",
          base: "0px",
        }}
        justifyContent="space-between"
        w="100%"
        height="83px"
        bg="#fff !important"
        zIndex={111}
        boxShadow={isAdminPath === "/admin" ? " 0 0 3px #ccc;" : null}
      >
        <Flex
          w="100%"
          gap={{ xl: 35, lg: 25, md: 13, sm: 5, base: 2 }}
          alignItems="center"
          justifyContent="space-between"
          mx={3}
        >
          <Flex
            w="100%"
            h="100%"
            justifyContent='space-between'
            alignItems="center"
            gap={{ xl: 3, lg: 3, md: 2, sm: 5, base: 2 }}

          >
            <Flex mr="0px" gap={2}>
              <IconButton
                display={{ '2xl': 'none', xl: 'none', lg: 'none', md: 'block', sm: 'block', base: 'block' }}
                onClick={onDrawerOpen}
                aria-label='Search database'
                icon={<HamburgerIcon fontSize='15px' />}
                size='sm'
                bg='transparent'
                _hover={{
                  bgColor: '#007bff29',
                  // boxShadow:'0 0 5px #007bff59',
                  color: '#007bff'
                }}
                sx={{
                  '&:hover p': {
                    color: '#007bff'
                  }
                }}
                transition='0.25s' />
              <Image
                src={Logo}
                alt="logo"
                onClick={() => navigate('/admin/dashboard')}
                cursor='pointer'
                w={{
                  xl: "160px",
                  lg: "160px",
                  md: "160px",
                  sm: "160px",
                  base: "160px",
                }}
                h="auto"
              />
            </Flex>
            <Flex justifyContent='space-evenly' gap={2} alignItems="center">
              <Link to='/admin/dashboard'>
                <Box
                  display={{
                    xl: "block",
                    lg: "block",
                    md: "none",
                    sm: "none",
                    base: "none",
                  }}
                  bgColor={location.pathname === '/admin/dashboard' ? '#007bff29' : 'transparent'}
                  borderRadius='4px'
                  p='4px 12px'
                  _hover={{
                    bgColor: '#007bff29',
                    // boxShadow:'0 0 5px #007bff59',
                    color: '#007bff'
                  }}
                  sx={{
                    '&:hover p': {
                      color: '#007bff'
                    }
                  }}
                  transition='0.25s'
                >
                  <Text
                    color={location.pathname === '/admin/dashboard' ? '#007bff' : "#000000"}
                    fontSize={{
                      xl: "15px",
                      lg: "13px",
                      md: "13px",
                      sm: "15px",
                      base: "15px",
                    }}
                    // _hover={{
                    //   bgColor: '#007bff29',
                    //   borderRadius:'2px',
                    //   boxShadow:'0 0 5px #007bff59',
                    //   color:'#007bff'
                    // }}
                    fontWeight="500"
                    cursor="pointer"
                  >
                    Dashboard
                  </Text>
                </Box>
              </Link>
              <Link to='/admin/jobs'>
                <Box
                  display={{
                    xl: "block",
                    lg: "block",
                    md: "none",
                    sm: "none",
                    base: "none",
                  }}
                  p='4px 12px'
                  bgColor={location.pathname === '/admin/jobs' ? '#007bff29' : 'transparent'}
                  borderRadius='3px'
                  _hover={{
                    bgColor: '#007bff29',
                    // boxShadow:'0 0 5px #007bff59',
                    color: '#007bff'
                  }}
                  sx={{
                    '&:hover p': {
                      color: '#007bff'
                    }
                  }}
                  transition='0.25s'
                >
                  <Text
                    color={location.pathname === '/admin/jobs' ? '#007bff' : "#000000"}
                    fontSize={{
                      xl: "15px",
                      lg: "13px",
                      md: "13px",
                      sm: "15px",
                      base: "15px",
                    }}
                    fontWeight="500"
                    cursor="pointer"
                  >
                    Jobs
                  </Text>
                </Box>
              </Link>
              <Link to='/admin/customers'>
                <Box
                  display={{
                    xl: "block",
                    lg: "block",
                    md: "none",
                    sm: "none",
                    base: "none",
                  }}
                  p='4px 12px'
                  bgColor={location.pathname === '/admin/customers' ? '#007bff29' : 'transparent'}
                  borderRadius='3px'
                  _hover={{
                    bgColor: '#007bff29',
                    // boxShadow:'0 0 5px #007bff59',
                    color: '#007bff'
                  }}
                  sx={{
                    '&:hover p': {
                      color: '#007bff'
                    }
                  }}
                  transition='0.25s'
                >
                  <Text
                    color={location.pathname === '/admin/customers' ? '#007bff' : "#000000"}
                    fontSize={{
                      xl: "15px",
                      lg: "13px",
                      md: "13px",
                      sm: "15px",
                      base: "15px",
                    }}
                    fontWeight="500"
                    cursor="pointer"
                  >
                    Customers
                  </Text>
                </Box>
              </Link>
              <Link to='/admin/backupdrive'>
                <Box
                  display={{
                    xl: "block",
                    lg: "block",
                    md: "none",
                    sm: "none",
                    base: "none",
                  }}
                  p='4px 12px'
                  bgColor={location.pathname === '/admin/backupdrive' ? '#007bff29' : 'transparent'}
                  borderRadius='3px'
                  _hover={{
                    bgColor: '#007bff29',
                    // boxShadow:'0 0 5px #007bff59',
                    color: '#007bff'
                  }}
                  sx={{
                    '&:hover p': {
                      color: '#007bff'
                    }
                  }}
                  transition='0.25s'
                >
                  <Text
                    w="max-content"
                    color={location.pathname === '/admin/backupdrive' ? '#007bff' : "#000000"}
                    fontSize={{
                      xl: "15px",
                      lg: "13px",
                      md: "13px",
                      sm: "15px",
                      base: "15px",
                    }}
                    fontWeight="500"
                    cursor="pointer"
                  >
                    Backup Drives
                  </Text>
                </Box>
              </Link>
              <Link to='/admin/leads'>
                <Box
                  display={{
                    xl: "block",
                    lg: "block",
                    md: "none",
                    sm: "none",
                    base: "none",
                  }}
                  p='4px 12px'
                  bgColor={location.pathname === '/admin/leads' ? '#007bff29' : 'transparent'}
                  borderRadius='3px'
                  _hover={{
                    bgColor: '#007bff29',
                    // boxShadow:'0 0 5px #007bff59',
                    color: '#007bff'
                  }}
                  sx={{
                    '&:hover p': {
                      color: '#007bff'
                    }
                  }}
                  transition='0.25s'
                >
                  <Text
                    color={location.pathname === '/admin/leads' ? '#007bff' : "#000000"}
                    fontSize={{
                      xl: "15px",
                      lg: "13px",
                      md: "13px",
                      sm: "15px",
                      base: "15px",
                    }}
                    fontWeight="500"
                    cursor="pointer"
                  >
                    Leads
                  </Text>
                </Box>
              </Link>
              <Link to='/admin/pickupdrop'>
                <Box
                  w="max-content"
                  display={{
                    xl: "block",
                    lg: "block",
                    md: "none",
                    sm: "none",
                    base: "none",
                  }}
                  p='4px 12px'
                  bgColor={location.pathname === '/admin/pickupdrop' ? '#007bff29' : 'transparent'}
                  borderRadius='3px'
                  _hover={{
                    bgColor: '#007bff29',
                    // boxShadow:'0 0 5px #007bff59',
                    color: '#007bff'
                  }}
                  sx={{
                    '&:hover p': {
                      color: '#007bff'
                    }
                  }}
                  transition='0.25s'
                >
                  <Text
                    w="max-content"
                    color={location.pathname === '/admin/pickupdrop' ? '#007bff' : "#000000"}
                    fontSize={{
                      xl: "15px",
                      lg: "13px",
                      md: "13px",
                      sm: "15px",
                      base: "15px",
                    }}
                    fontWeight="500"
                    cursor="pointer"
                  >
                    Pickup/Drops
                  </Text>
                </Box>
              </Link>

            </Flex>
          </Flex>


          <Flex
            // justifyContent='center'
            alignItems="center"
          // w='100%'
          // gap={{ xl: 25, lg: 25, md: 13, sm: 5, base: 2 }}
          >
            <Box
              mx="10px"
              minW={{
                xl: "250px",
                lg: "150px",
                md: "150px",
                sm: "250px",
                base: "250px",
              }}
              display={{
                xl: "block",
                lg: "block",
                md: "none",
                sm: "none",
                base: "none",
              }}
            >
              {/* <Text color='#000000' fontSize='15px' lineHeight='18.75px' fontWeight='500' cursor='pointer'> Settings</Text> */}
              <Stack spacing={4}>
                {/* <Tooltip label="Global Search" fontSize='12px'> */}
                <InputGroup bg="#F1F5F7" borderRadius="10px" cursor='pointer' _hover={{ filter: 'brightness(90%)' }} onClick={onSearchOpen}>
                  <InputLeftAddon border="0" px="10px" bg="#F1F5F7">
                    {" "}
                    <IoSearchOutline fontSize="20" />{" "}
                  </InputLeftAddon>
                  <Input
                    cursor='pointer'
                    type="text"
                    px="5px"
                    placeholder="Search"
                    border="0"
                    outline="none"
                    _focus={{ boxShadow: "none", border: "0px" }}
                    borderRadius="10px"
                  />
                </InputGroup>
                {/* </Tooltip> */}
              </Stack>
            </Box>

            <Menu isOpen={isMenuOpen} onClose={onMenuClose}>
              <Tooltip isOpen={isTooltipOpen} label={`${userDetails?.name} (${userDetails?.role})`} fontSize='12px'>
                <MenuButton
                  as={Button}
                  w="max-content"
                  p={0}
                  h="max-content"
                  minW="max-content"
                  borderRadius={50}
                  onClick={() => { onMenuOpen(); setIsTooltipOpen(false) }}
                  onMouseOver={() => setIsTooltipOpen(true)}
                  onMouseOut={() => setIsTooltipOpen(false)}
                >
                  <Box
                  // display={{
                  //   xl: "block",
                  //   lg: "block",
                  //   md: "none",
                  //   sm: "none",
                  //   base: "none",
                  // }}
                  >
                    <Avatar
                      size="sm"
                      name={userDetails?.name}
                      src={userDetails?.profile_photo ? userDetails?.profile_photo : ''}
                    />
                  </Box>
                </MenuButton>
              </Tooltip>
              <MenuList zIndex={999999}>
                <MenuItem
                  as='a'
                  href={`/#/admin/userprofile`}
                  fontSize="14px"
                  onClick={(e) => { e.preventDefault(); navigate(`/admin/userprofile`) }}
                >
                  <Link to={`/#/admin/userprofile`}>
                    <Text>Profile</Text>
                  </Link>
                </MenuItem>
                {encrypted?.user_type === 'admin' ? (<>
                  <MenuItem fontSize="14px" onClick={() => navigate('/admin/reports')}>
                    <Link to={`/admin/reports`}>
                      Reports
                    </Link>
                  </MenuItem>
                  <MenuItem
                    display={{ base: 'none', lg: 'block' }}
                    as='a'
                    href="/#/setting/business"
                    fontSize="14px"
                    onClick={(e) => { e.preventDefault(); navigate(`/setting/business`) }}
                  >
                    <Link to='/setting/business'>
                      <Text>Settings</Text>
                    </Link>
                  </MenuItem>
                </>) : ('')}

                <Accordion display={{ base: 'block', lg: 'none' }} defaultIndex={[-1]} w={"100%"} allowMultiple>
                  <AccordionItem w={"100%"}>
                    <AccordionButton bg={'transparent'}>
                      <Box ms={-1} as='span' fontSize="14px" s flex='1' textAlign='left'>
                        Settings
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel px={2} py={1} display={{ base: 'block', md: 'none' }} ms={0} fontSize="14px"><Link to='/setting/business'>
                      <Flex as='button' gap={3} alignItems='center' bgColor={location.pathname === '/setting/business' ? 'white' : 'transparent'} onClick={() => { navigate('/setting/business'); onMenuClose() }} my={0.5} _hover={{ backgroundColor: 'white' }} p='2px 8px' borderRadius='10px' transition='.25s' w='100%'>
                        <FiUsers fontSize='20' color={location.pathname === '/setting/business' ? 'rgb(0, 115, 255)' : '#6f6f6f'} />
                        <Text color={location.pathname === '/setting/business' ? 'rgb(0, 115, 255)' : '#6f6f6f'} fontSize='14px' textAlign={'left'}>Business Information</Text>
                      </Flex>
                    </Link></AccordionPanel>
                    <AccordionPanel px={2} py={1} display={{ base: 'block', md: 'none' }} ms={0} fontSize="14px">
                      <Flex as='button' gap={3} alignItems='center' bgColor={location.pathname === '/setting/general_setting' ? 'white' : 'transparent'} onClick={() => { navigate('/setting/general_setting'); onMenuClose() }} my={0.5} _hover={{ backgroundColor: 'white' }} p='2px 8px' borderRadius='10px' transition='.25s' w='100%'>
                        <LuCalendarDays fontSize='20' color={location.pathname === '/setting/general_setting' ? 'rgb(0, 115, 255)' : '#6f6f6f'} />
                        <Text color={location.pathname === '/setting/general_setting' ? 'rgb(0, 115, 255)' : '#6f6f6f'} fontSize='14px'>General Settings</Text>
                      </Flex>
                    </AccordionPanel>
                    <AccordionPanel px={2} py={1} display={{ base: 'block', md: 'none' }} ms={0} fontSize="14px">
                      <Flex as='button' gap={3} alignItems='center' bgColor={location.pathname === '/setting/job_setting' ? 'white' : 'transparent'} onClick={() => { navigate('/setting/job_setting'); onMenuClose() }} my={0.5} _hover={{ backgroundColor: 'white' }} p='2px 8px' borderRadius='10px' transition='.25s' w='100%'>
                        <TbEdit fontSize='20' color={location.pathname === '/setting/job_setting' ? 'rgb(0, 115, 255)' : '#6f6f6f'} />
                        <Text color={location.pathname === '/setting/job_setting' ? 'rgb(0, 115, 255)' : '#6f6f6f'} fontSize='14px'>Job Settings</Text>
                      </Flex>
                    </AccordionPanel>
                    <AccordionPanel px={2} py={1} display={{ base: 'block', md: 'none' }} ms={0} fontSize="14px">
                      <Flex as='button' gap={3} alignItems='center' bgColor={location.pathname === '/setting/Employee_setting' ? 'white' : 'transparent'} onClick={() => { navigate('/setting/Employee_setting'); onMenuClose() }} my={0.5} _hover={{ backgroundColor: 'white' }} p='2px 8px' borderRadius='10px' transition='.25s' w='100%'>
                        <MdOutlineInsertChartOutlined fontSize='20' color={location.pathname === '/setting/Employee_setting' ? 'rgb(0, 115, 255)' : '#6f6f6f'} />
                        <Text color={location.pathname === '/setting/Employee_setting' ? 'rgb(0, 115, 255)' : '#6f6f6f'} fontSize='14px'>Employee Settings</Text>
                      </Flex>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>


                <MenuItem
                  fontSize="14px"
                  onClick={onChangeOpen}
                >
                  <Link>
                    <Text>Change Password</Text>
                  </Link>
                </MenuItem>
                <MenuItem
                  fontSize="14px"
                  onClick={handleLogout} >
                  Log Out
                </MenuItem>
              </MenuList>
            </Menu>
            <Tooltip label='Notifications' fontSize='12px'>
              <Box ml="10px" position='relative'>
                <Box position='absolute' bgColor='#e45959' p={1.5} lineHeight='0.5' borderRadius={10} top='-10px' right='-10px'>
                  <Text fontSize='11px' color='white' key={empNotifications?.filter((element) => element.seen === "0").length}>{empNotifications?.filter((element) => element.seen === "0").length > 9 ? `9+` : empNotifications?.filter((element) => element.seen === "0").length}</Text>
                </Box>
                <FaRegBell fontSize="20" onClick={onOpen} cursor="pointer" />
              </Box>
            </Tooltip>
          </Flex>
        </Flex>
        <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="sm">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Notifications</DrawerHeader>
            <DrawerBody p="0px" bgColor="#fafbff">
              <Tabs position="relative" variant="unstyled">
                <Flex justifyContent="space-between" bgColor="#fff">
                  <TabList px={3}>
                    <Tab fontSize="13px" _active={{ color: "#5a80dc" }}>
                      All
                    </Tab>
                    <Tab fontSize="13px" _active={{ color: "#5a80dc" }}>
                      Unread
                    </Tab>
                    <Tab fontSize="13px" _active={{ color: "#5a80dc" }}>
                      Read
                    </Tab>
                  </TabList>
                  <Button
                    fontSize="12px"
                    padding="0px 8px"
                    height="auto"
                    color="#5a80dc"
                    bg="transparent"
                    onClick={markAllSeen}
                    isLoading={loading}
                  >
                    Mark all as read
                  </Button>
                </Flex>
                <TabIndicator
                  mt="-1.5px"
                  height="2px"
                  bg="blue.500"
                  borderRadius="1px"
                />
                <TabPanels height="100%">
                  <TabPanel px={0} bgColor="#fafbff">
                    {sortedNotification.length !== 0 ? (
                      sortedNotification?.map((element, index) => (
                        <Box key={index}>
                          <Text
                            fontSize="12px"
                            px={1}
                            mt={3}
                            mb={2}
                            letterSpacing="1.5px"
                          >
                            {element.day.toUpperCase()}
                          </Text>
                          {element.notifications?.map((element, index) => (
                            <NotificationElement
                              key={index}
                              element={element}
                              onClose={onClose}
                            />
                          ))}
                        </Box>
                      ))
                    ) : (
                      <Text textAlign="center" fontSize="13px">
                        Nothing here
                      </Text>
                    )}
                  </TabPanel>
                  <TabPanel px={0} bgColor="#fafbff">
                    {unSeenNotifications.length !== 0 ? (
                      unSeenNotifications?.map((element, index) => (
                        <Box key={index}>
                          <Text
                            fontSize="12px"
                            px={1}
                            mt={3}
                            mb={2}
                            letterSpacing="1.5px"
                          >
                            {element.day.toUpperCase()}
                          </Text>
                          {element.notifications?.map((element, index) => (
                            <NotificationElement
                              key={index}
                              element={element}
                            />
                          ))}
                        </Box>
                      ))
                    ) : (
                      <Text textAlign="center" fontSize="13px">
                        Nothing here
                      </Text>
                    )}
                  </TabPanel>
                  <TabPanel px={0} bgColor="#fafbff">
                    {seenNotifications.length !== 0 ? (
                      seenNotifications?.map((element, index) => (
                        <Box key={index}>
                          <Text
                            fontSize="12px"
                            px={1}
                            mt={3}
                            mb={2}
                            letterSpacing="1.5px"
                          >
                            {element.day.toUpperCase()}
                          </Text>
                          {element.notifications?.map((element, index) => (
                            <NotificationElement
                              key={index}
                              element={element}
                            />
                          ))}
                        </Box>
                      ))
                    ) : (
                      <Text textAlign="center" fontSize="13px">
                        Nothing here
                      </Text>
                    )}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        <Modal isOpen={isChangeOpen} onClose={onChangeClose} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Change Password</ModalHeader>
            <ModalCloseButton />
            <ModalBody as='form'>
              <FormControl>
                <FormLabel fontSize="12px" mb='0px'>
                  Current Password
                  <Text as="span" color="red" fontSize="12" ml={1}>
                    *
                  </Text>
                </FormLabel>
                <Input
                  type="password"
                  fontSize="14px"
                  alignContent={"center"}
                  placeholder="Password"
                  onChange={handleOnChange}
                  name="current_password"
                  value={creds.current_password}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel fontSize="12px" mb='0px'>
                  New Password
                  <Text as="span" color="red" fontSize="12" ml={1}>
                    *
                  </Text>
                </FormLabel>
                <Input
                  fontSize="14px"
                  placeholder="New password"
                  type="password"
                  onChange={handleOnChange}
                  value={creds.new_password}
                  name="new_password"
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel fontSize="12px" mb='0px'>
                  Confirm Password
                  <Text as="span" color="red" fontSize="12" ml={1}>
                    *
                  </Text>
                </FormLabel>
                <Input
                  fontSize="14px"
                  type="password"
                  placeholder="Re-type password"
                  value={creds.confirm_password}
                  onChange={handleOnChange}
                  name="confirm_password"
                />
              </FormControl>
            </ModalBody>
            <ModalFooter gap={2}>
              <Button
                onClick={() => {
                  onChangeClose();
                  setCreds({
                    current_password: "",
                    new_password: "",
                    confirm_password: ""
                  })
                }}
                bg="#fff"
                border={"1px solid #e16a7a"}
                color={"#e16a7a"}
                _hover={{
                  bg: "#e16a7a",
                  border: "1px solid #e16a7a",
                  color: "#fff",
                }}
                fontSize={'14px'}
              >
              Cancel
            </Button>
            <Button

              fontSize="14px"
              bgColor="#030228"
              color="white"
              onClick={handleChangePassword}
              isLoading={loading}
              _hover={{ color: "white", background: "#030228" }}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Drawer isOpen={isDrawerOpen} placement="left" onClose={onDrawerClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader bgColor="#fafbff" >Menu</DrawerHeader>
          <DrawerBody p="0px" bgColor="#fafbff">
            <Box
              w="100%"
              gap={{ xl: 35, lg: 25, md: 13, sm: 5, base: 2 }}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box
                w="100%"
                h="100%"
                justifyContent='space-between'
                alignItems="center"
                gap={{ xl: 3, lg: 3, md: 2, sm: 5, base: 2 }}
              >
                <Box
                  mx="10px"
                  minW={{
                    xl: "250px",
                    lg: "150px",
                    md: "150px",
                    sm: "250px",
                    base: "250px",
                  }}
                >
                  {/* <Text color='#000000' fontSize='15px' lineHeight='18.75px' fontWeight='500' cursor='pointer'> Settings</Text> */}
                  <Stack spacing={4}>
                    <InputGroup bg="#F1F5F7" borderRadius="10px" cursor='pointer' _hover={{ filter: 'brightness(90%)' }} onClick={onSearchOpen}>
                      <InputLeftAddon border="0" px="10px" bg="#F1F5F7">
                        {" "}
                        <IoSearchOutline fontSize="20" />{" "}
                      </InputLeftAddon>
                      <Input
                        cursor='pointer'
                        type="text"
                        px="5px"
                        placeholder="Search"
                        border="0"
                        outline="none"
                        _focus={{ boxShadow: "none", border: "0px" }}
                        borderRadius="10px"
                      />
                    </InputGroup>
                  </Stack>
                </Box>
                <Flex flexDir='column' justifyContent='space-evenly' gap={2} p={2} justifyItems='center'>
                  <Link to='/admin/dashboard' onClick={onDrawerClose}>
                    <Box
                      bgColor={location.pathname === '/admin/dashboard' ? '#007bff29' : 'transparent'}
                      borderRadius='4px'
                      p='4px 12px'
                      _hover={{
                        bgColor: '#007bff29',
                        // boxShadow:'0 0 5px #007bff59',
                        color: '#007bff'
                      }}
                      sx={{
                        '&:hover p': {
                          color: '#007bff'
                        }
                      }}
                      transition='0.25s'
                    >
                      <Text
                        color={location.pathname === '/admin/dashboard' ? '#007bff' : "#000000"}
                        fontSize={{
                          xl: "15px",
                          lg: "13px",
                          md: "13px",
                          sm: "15px",
                          base: "15px",
                        }}
                        // _hover={{
                        //   bgColor: '#007bff29',
                        //   borderRadius:'2px',
                        //   boxShadow:'0 0 5px #007bff59',
                        //   color:'#007bff'
                        // }}
                        fontWeight="500"
                        cursor="pointer"
                      >
                        Dashboard
                      </Text>
                    </Box>
                  </Link>
                  <Link to='/admin/jobs' onClick={onDrawerClose}>
                    <Box
                      p='4px 12px'
                      bgColor={location.pathname === '/admin/jobs' ? '#007bff29' : 'transparent'}
                      borderRadius='3px'
                      _hover={{
                        bgColor: '#007bff29',
                        // boxShadow:'0 0 5px #007bff59',
                        color: '#007bff'
                      }}
                      sx={{
                        '&:hover p': {
                          color: '#007bff'
                        }
                      }}
                      transition='0.25s'
                    >
                      <Text
                        color={location.pathname === '/admin/jobs' ? '#007bff' : "#000000"}
                        fontSize={{
                          xl: "15px",
                          lg: "13px",
                          md: "13px",
                          sm: "15px",
                          base: "15px",
                        }}
                        fontWeight="500"
                        cursor="pointer"
                      >
                        Jobs
                      </Text>
                    </Box>
                  </Link>
                  <Link to='/admin/customers' onClick={onDrawerClose}>
                    <Box
                      p='4px 12px'
                      bgColor={location.pathname === '/admin/customers' ? '#007bff29' : 'transparent'}
                      borderRadius='3px'
                      _hover={{
                        bgColor: '#007bff29',
                        // boxShadow:'0 0 5px #007bff59',
                        color: '#007bff'
                      }}
                      sx={{
                        '&:hover p': {
                          color: '#007bff'
                        }
                      }}
                      transition='0.25s'
                    >
                      <Text
                        color={location.pathname === '/admin/customers' ? '#007bff' : "#000000"}
                        fontSize={{
                          xl: "15px",
                          lg: "13px",
                          md: "13px",
                          sm: "15px",
                          base: "15px",
                        }}
                        fontWeight="500"
                        cursor="pointer"
                      >
                        Customers
                      </Text>
                    </Box>
                  </Link>
                  <Link to='/admin/backupdrive' onClick={onDrawerClose}>
                    <Box
                      p='4px 12px'
                      bgColor={location.pathname === '/admin/backupdrive' ? '#007bff29' : 'transparent'}
                      borderRadius='3px'
                      _hover={{
                        bgColor: '#007bff29',
                        // boxShadow:'0 0 5px #007bff59',
                        color: '#007bff'
                      }}
                      sx={{
                        '&:hover p': {
                          color: '#007bff'
                        }
                      }}
                      transition='0.25s'
                    >
                      <Text
                        w="max-content"
                        color={location.pathname === '/admin/backupdrive' ? '#007bff' : "#000000"}
                        fontSize={{
                          xl: "15px",
                          lg: "13px",
                          md: "13px",
                          sm: "15px",
                          base: "15px",
                        }}
                        fontWeight="500"
                        cursor="pointer"
                      >
                        Backup Drives
                      </Text>
                    </Box>
                  </Link>
                  <Link to='/admin/leads' onClick={onDrawerClose}>
                    <Box
                      p='4px 12px'
                      bgColor={location.pathname === '/admin/leads' ? '#007bff29' : 'transparent'}
                      borderRadius='3px'
                      _hover={{
                        bgColor: '#007bff29',
                        // boxShadow:'0 0 5px #007bff59',
                        color: '#007bff'
                      }}
                      sx={{
                        '&:hover p': {
                          color: '#007bff'
                        }
                      }}
                      transition='0.25s'
                    >
                      <Text
                        color={location.pathname === '/admin/leads' ? '#007bff' : "#000000"}
                        fontSize={{
                          xl: "15px",
                          lg: "13px",
                          md: "13px",
                          sm: "15px",
                          base: "15px",
                        }}
                        fontWeight="500"
                        cursor="pointer"
                      >
                        Leads
                      </Text>
                    </Box>
                  </Link>
                  <Link to='/admin/pickupdrop' onClick={onDrawerClose}>
                    <Box
                      w="max-content"
                      p='4px 12px'
                      bgColor={location.pathname === '/admin/pickupdrop' ? '#007bff29' : 'transparent'}
                      borderRadius='3px'
                      _hover={{
                        bgColor: '#007bff29',
                        // boxShadow:'0 0 5px #007bff59',
                        color: '#007bff'
                      }}
                      sx={{
                        '&:hover p': {
                          color: '#007bff'
                        }
                      }}
                      transition='0.25s'
                    >
                      <Text
                        w="max-content"
                        color={location.pathname === '/admin/pickupdrop' ? '#007bff' : "#000000"}
                        fontSize={{
                          xl: "15px",
                          lg: "13px",
                          md: "13px",
                          sm: "15px",
                          base: "15px",
                        }}
                        fontWeight="500"
                        cursor="pointer"
                      >
                        Pickup/Drops
                      </Text>
                    </Box>
                  </Link>

                </Flex>
              </Box>
              <Flex
                // justifyContent='center'
                alignItems="center"
                // w='100%'
                mr="10px"
              // gap={{ xl: 25, lg: 25, md: 13, sm: 5, base: 2 }}
              >
              </Flex>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Modal isCentered isOpen={isSearchOpen} onClose={() => { onSearchClose(); setSearchQuery(''); setSearchResults([]) }} size='xl'>
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(3px)' />
        <ModalContent>
          <ModalHeader>
            <InputGroup size='md'>
              <InputLeftElement pointerEvents='none' color='gray.300' fontSize='1.2em'>
                {loading ? <Spinner size='sm' color='gray.600' /> : <SearchIcon color='gray.600' fontSize='xs' />}
              </InputLeftElement>
              <Input
                type={'text'}
                fontSize='13px'
                placeholder='Type anything...'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <InputRightElement width='2.5rem'>
                <IconButton size='xs' aria-label='Search database' icon={<CloseIcon />} onClick={() => { setSearchQuery(''); setSearchResults([]) }} bgColor='transparent' />
              </InputRightElement>
            </InputGroup>
          </ModalHeader>
          <ModalBody p={0}>
            {searchResults[0] ? <Tabs>
              <TabList overflowX='auto' overflowY='hidden'>
                {[{ category: 'All' }, ...searchResults]?.map((element, index) => <Tab key={index} fontSize='12px'>{element.category}</Tab>)}
              </TabList>
              <TabPanels>
                <TabPanel p={0} mt={2} overflowY='scroll' h='calc(100vh - 300px)'>
                  {searchResults[0] && searchResults?.map((element, index) => {
                    return <Box>
                      <SearchComponent key={index} category={element.category} result={element.result} handleCloseSearch={onSearchClose} />
                      <Divider my={1} />
                    </Box>
                  })}
                </TabPanel>
                {searchResults?.map((elem, index) => <TabPanel key={index} p={0} mt={2} overflowY='scroll' h='calc(100vh - 300px)'>
                  {searchResults[0] && searchResults?.filter(element => element.category === elem.category).map((element, index) => {
                    return <Box>
                      <SearchComponent key={index} category={element.category} result={element.result} handleCloseSearch={onSearchClose} />
                      <Divider my={1} />
                    </Box>
                  })}
                </TabPanel>)}
              </TabPanels>
            </Tabs> : <Text fontSize='13px' textAlign='center'>{searchQuery !== '' && (!searchResults[0] && 'Not found')}</Text>}
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => { onSearchClose(); setSearchQuery(''); setSearchResults([]) }} size='sm'>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </HStack >
    </>
  );
}

export default Navbar;
