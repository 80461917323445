import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, Divider, Flex, FormControl, FormLabel, Image, Input, Spinner, Text } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Logo from '../../assets/Admin/logo.png';
import { JobContext } from '../../context/JobContext';
import { IoShieldCheckmarkOutline } from 'react-icons/io5';

const ActivateAccount = ({ showAlert }) => {
    const { details } = useParams()
    const { formatDate, decryptActivate } = useContext(JobContext)
    const [loading, setLoading] = useState(false)
    const [mailDetails, setMailDetails] = useState(null)
    const [boxDisplay, setBoxDisplay] = useState(true)

    const [creds, setCreds] = useState({
        password: '',
        cpassword: ''
    })

    useEffect(() => {
        decryptData()

    }, [details])

    const [afterMsg, setAfterMsg] = useState({
        status: false,
        message: ''
    })

    const decryptData = async () => {
        const decrypted = await decryptActivate(details)
        if (decrypted?.decryptedData) {
            let jsonDecyptdData = JSON.parse(decrypted?.decryptedData)
            setMailDetails(jsonDecyptdData)
            if (!decrypted?.account_status) {
                setAfterMsg({
                    status: true,
                    title: 'Link Expired!',
                    type: 'error',
                    message: 'The link you have received has been expired or already updated'
                })
                setBoxDisplay(false)
            }
        } else {
            setMailDetails(decrypted)
        }
    }

    const handleChange = (e) => {
        setCreds({ ...creds, [e.target.name]: e.target.value })
    }

    const handleChangePassword = async () => {
        setLoading(true)
        setBoxDisplay(false)
        if (creds?.password !== creds?.cpassword) {
            setLoading(false)
            setBoxDisplay(true)
            return showAlert("Password fields does not match", 'error')
        }
        try {
            const response = await fetch(
                `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/customers/updatePassword`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        customer_id: mailDetails?.customer_id,
                        password: creds.password
                    }),
                }
            );
            const data = await response.json();
            if (data.success) {
                // showAlert('Request Sent', "success");
                setBoxDisplay(false)
                setLoading(false)
                setAfterMsg({
                    status: true,
                    type: 'success',
                    title: 'Success!',
                    message: `Remote session request has been approved`
                })
            } else {
                setLoading(false);
                setBoxDisplay(false)
                setAfterMsg({
                    status: true,
                    type: 'error',
                    title: 'Something went wrong!',
                    message: null
                })
                showAlert(data.error, "error");
            }

        } catch (error) {
            setLoading(false);
            setBoxDisplay(false)
            setAfterMsg({
                status: true,
                type: 'error',
                title: 'Internal server error!',
                message: null
            })
            showAlert("Internal server error", "error");
        }
    }

    return (
        <Box >
            <Image m={4} src={Logo} alt='logo' w={{ xl: '212px', lg: '212px', md: '212px', sm: '212px', base: '212px' }} h='auto' />
            <Flex h='calc(100vh - 173px)' w='100%' alignItems='center' justifyContent='center'>
                {loading && <Spinner
                    thickness='3px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='blue.600'
                    size='xl'
                />}
                {boxDisplay && <Box border='1px solid #ccc' borderRadius={8} padding={7} bgColor='white' w={{ xl: '30%', lg: '40%', md: '50%', sm: '80%', base: '90%' }}>
                    <Text fontSize='13px' letterSpacing='1px'>ACTIVATE YOUR ACCOUNT</Text>
                    <Flex gap={4} my={5}>
                        <IoShieldCheckmarkOutline fontSize='50' />
                        <Box>
                            <Text>Set Your Password</Text>
                            <Text fontSize='13px'>{formatDate(mailDetails?.current_time, true, true)}</Text>
                        </Box>
                    </Flex>
                    <Divider />
                    <Box my={5}>
                        <FormControl>
                            <FormLabel fontSize='13px' mb={1}>New Password</FormLabel>
                            <Input type='password' size={{ '2xl': 'md', xl: 'md', lg: 'md', md: 'md', sm: 'sm', base: 'sm' }} name='password' value={creds.password} onChange={handleChange} />
                        </FormControl>
                        <FormControl mt={2}>
                            <FormLabel fontSize='13px' mb={1}>Confirm Password</FormLabel>
                            <Input type='password' size={{ '2xl': 'md', xl: 'md', lg: 'md', md: 'md', sm: 'sm', base: 'sm' }} name='cpassword' value={creds.cpassword} onChange={handleChange} />
                        </FormControl>
                    </Box>
                    <Divider />
                    <Flex mt={5} justifyContent='center' gap={5}>
                        <Button size='sm' colorScheme='green' variant='solid' gap={0} display='flex' onClick={handleChangePassword} isLoading={loading}>
                            Submit
                        </Button>
                    </Flex>
                </Box>}
                {afterMsg?.status && <Alert
                    status={afterMsg?.type}
                    variant='subtle'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    textAlign='center'
                    height='200px'
                    w={{ xl: '50%', lg: '50%', md: '60%', sm: '80%', base: '80%' }}
                >
                    <AlertIcon boxSize='40px' mr={0} />
                    <AlertTitle mt={4} mb={1} fontSize='lg'>
                        {afterMsg?.title}
                    </AlertTitle>
                    {afterMsg?.message && <AlertDescription maxWidth='sm'>
                        {afterMsg?.message}
                    </AlertDescription>}
                </Alert>}
            </Flex>
        </Box>
    )
}

export default ActivateAccount