import * as Yup from 'yup'; 

export const CustomerSchema = Yup.object({
  
    customer_name: Yup.string().required("Please enter your name."),
    customer_type: Yup.string().required("Please enter your type."),
    mobile_no: Yup.string().matches(/^\d+$/, "Phone number must be digits only").required("Please enter mobile number."),
    email: Yup.string().email('Invalid email format.').required("Please enter your email."),

})



export const passwordValidationSchema = Yup.object().shape({
    current_password: Yup.string()
    .required('Current password is required.'),
  
    new_password: Yup.string()
    .required('New password is required.')
    .min(8, 'Password must be at least 8 characters long.')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(/[\W_]/, 'Password must contain at least one special character')
    .notOneOf([Yup.ref('currentPassword'), null], 'New password must be different from the current password.'),
});



